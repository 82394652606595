/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Define some frequent-use constants
 * for easier import inside components
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import _, { toNumber } from 'lodash'
import moment from 'moment'
import { PersistConfig } from 'redux-persist'
import { GoogleAdItemType } from './components/GoogleAdsVisualisation/components/GoogleAdsEditModal'
import { EReportComponents } from './components/Report/components/CreateReportTemplate'
import {
	CustomProductLabel,
	FacebookAdsFilterOptions,
	GoogleAdsFilterOptions,
} from './reducers/ads'
import i18n from './services/i18n'
import { Company } from './reducers/user'
import { keywordRankingWithLabel } from './components/KeywordRanking/KeywordRanking'

// Languages
export const languages = {
	en: 'en',
	fi: 'fi',
}

export const supportEmail = 'info@dashboa.com'

export const ENVIRONMENTS = {
	LOCAL: 'dev-local',
	STAGING: 'dev-staging',
	PRODUCTION: 'production',
}
// To switch between LOCAL & STAGING backend
export const environment = ENVIRONMENTS.PRODUCTION
export const stagingBackendUri =
	process.env.BACKEND_BASE_URL ||
	'https://datatool-staging-dot-crono-291610.ey.r.appspot.com'
export const backendUri =
	process.env.REACT_APP_BACKEND_BASE_URL || process.env.BACKEND_BASE_URL || ''

export const authFacebook = backendUri + '/auth/facebook'
export const authGoogle = backendUri + '/auth/google'
// Key redux persist
export const PERSIST_KEY = 'root'
export const privacyPolicy = 'https://dashboa.com/privacy'

// Color code
export const primaryColor = '#001974'
export const secondPrimary = '#001974'
export const lightPrimaryColor = '#e0f8ee'
// Router path
export const ROUTER_PATH = {
	DASHBOARD: '/dashboard/:component',
	OVERVIEW: '/dashboard/overview',
	HOME: '/dashboard/home',
	SETTING: '/settings',
	TASKS: '/tasks',
	PROFILE: '/profile',
	PROJECTS: '/settings/projects',
	PROJECT_SETTINGS: '/project-settings',
	SUBSCRIPTION: '/settings/subscription',
	AUDIT: '/tools/audit',
	KEYWORD_RANKING: '/tools/keyword-ranking',
	KEYWORD_PLANNER: '/tools/keyword-planner',
	COMPETITOR_EXPLORER: '/tools/competitor-explorer',
	PRICE_SCALPING: '/tools/price-scalping',
	CATEGORY_CREATOR: '/tools/category-creator',
	ADS_VISUALISATION: '/tools/ads-visualisation',
	GOOGLE_CHANGE_EVENTS: '/tools/google-change-events',
	ROAS_ALLOGATOR: '/tools/roas-allogator',
	ECOMMERCE_FEED: '/tools/ecommerce-feed',
	LOGIN: '/signin',
	SIGNIN: '/login',
	SIGNUP: '/signup',
	REGISTER: '/register',
	FORGET_PASSWORD: '/forget-password',
	RESET_PASSWORD: '/reset-password',
	NO_SERVICE: '/no-service',
	REPORTS: '/report/generate/:year/:month',
	CUSTOM_REPORT: '/report/generate/custom',
	REPORTS_TEMPLATE: '/report/templates/:route',
	REPORTS_CREATE_TEMPLATE: '/report/templates/create',
	REPORTS_EDIT_TEMPLATE: '/report/templates/edit',
	REPORTS_TEMPLATES: '/report/templates/templates',
	REPORTS_ECOMMERCE: '/report/ecommerce',
	REPORTS_MARKETING: '/report/marketing',
	MAIN_SETTINGS: '/main-settings',
	GOOGLE_ANALYTICS_TAB: '/google-analytics',
	GOOGLE_ADS_TAB: '/google-ads',
	FACEBOOK_TAB: '/facebook',
	POST_SCHEDULER: '/content-tools/post-scheduler/:component',
	POST_SCHEDULER_NEW_POST: '/content-tools/post-scheduler/new-post',
	POST_SCHEDULER_CALENDAR: '/content-tools/post-scheduler/calendar',
	POST_SCHEDULER_WITH_DATE: '/content-tools/post-scheduler/:component/:date',
	POST_SCHEDULER_HISTORY: '/content-tools/post-scheduler/history',
	EDIT_POST_ROUTE: '/content-tools/post-scheduler/:component/:type/:id',
	EDIT_POST: '/content-tools/post-scheduler/edit-post/',
	AI_FINETUNE_FILE_CHECK: '/content-tools/finetune/file-check',
	AI_CONTENT_CREATOR: '/content-tools/ai-content-creator/:component',
	AI_CONTENT_CREATOR_BLOG_POST:
		'/content-tools/ai-content-creator/article-creator',
	AI_CONTENT_CREATOR_GOOGLE_ADS:
		'/content-tools/ai-content-creator/google-ads-content-creator',
	AI_CONTENT_CREATOR_BLOG_POST_LEGACY:
		'/content-tools/ai-content-creator/content-creator-legacy',
	// AI_CONTENT_CREATOR_BULK_CREATION:
	// 	'/content-tools/ai-content-creator/bulk-creation',
	AI_CONTENT_CREATOR_CHAT: '/content-tools/ai-content-creator/chat',
	PRODUCT_DESCRIPTION_CREATOR:
		'/content-tools/ai-content-creator/description-creator',
	CATEGORY_DESCRIPTION_CREATOR:
		'/content-tools/ai-content-creator/category-description-creator',
	PRODUCT_ARTICLE_CREATOR:
		'/content-tools/ai-content-creator/product-article-creator',
	DESCRIPTION_BULK: '/content-tools/ai-content-creator/description-bulk',
	PROJECT_CREATION_WIZARD_ROUTE: '/project-wizard/:step',
	PROJECT_CREATION_WIZARD: '/project-wizard/',
	AUTOMATIC_REPORT: '/automatic-report/:tokenId',
	WP_CONTENT: '/content-tools/wp-content',
	WP_CONTENT_POST_BY_ID: '/content-tools/wp-content/:type/:contentId',
	WP_CONTENT_EDIT_BY_TYPE_AND_ID:
		'/content-tools/wp-content/edit/:type/:contentId',
	WP_CONTENT_CREATE_BY_TYPE: '/content-tools/wp-content/new/:type',
	COMPANY: '/company',
	AI_CONTENT_IN_BULK: '/dashboard/ai-content-in-bulk',
	CUSTOMIZE_REPORT: '/report/customize-report',
	RANK_PREDICTION: '/tools/rank-predictor',
	PROJECT_INVITATION: '/projectInvitation',
}

export const API = {
	GET_CURRENT_USER: '/users/me',
	GET_CURRENT_USER_BY_ID: '/users/:id',
	GET_COMPANY_USERS: 'company/{companyId}',
	GET_COUNTRIES: '/users/get/countries',
	SIGN_IN: '/auth/signin',
	SIGN_OUT: '/auth/signout',
	SIGN_UP: '/auth/signup',
	GET_FIREBASE_CUSTOM_TOKEN: '/auth/firebase/token',
	UPDATE_USER: '/users/{id}',
	UPDATE_SUBSCRIBER_PROJECT: '/projects/{projectId}/subscribers',
	AUTH_WITH_FACEBOOK: '/auth/facebook',
	AUTH_WITH_GOOGLE: '/auth/google',
	FORGET_PASSWORD: '/auth/password/forget',
	RESET_PASSWORD: '/auth/password/reset',
	AUTH_DATASOURCE_FACEBOOK_PAGE: '/datasource/facebook/facebook_page/auth',
	AUTH_DATASOURCE_FACEBOOK_AD_MANAGER:
		'/datasource/facebook/facebook_ad_manager/auth',
	AUTH_DATASOURCE_GOOGLE_ADS: '/datasource/google/ads/auth',
	AUTH_DATASOURCE_GOOGLE_ANALYTICS: '/datasource/google/analytics/auth',
	AUTH_DATASOURCE_GOOGLE_ANALYTICS_GA4: '/datasource/google/analyticsGA4/auth',
	AUTH_DATASOURCE_GOOGLE_SEARCH: '/datasource/google/search/auth',
	AUTH_DATASOURCE_WORDPRESS: '/datasource/wordpress/auth',
	AUTH_DATASOURCE_LINKEDIN: '/datasource/linkedin/auth',
	AUTH_DATASOURCE_GOOGLE_MERCHANT: '/datasource/google/merchant/auth',
	VALIDATE_EDIT_GOOGLE_KEYWORD:
		'ads/{projectId}/editSpecial/keyword/?validate=true',
	SAVE_EDIT_GOOGLE_KEYWORD:
		'ads/{projectId}/editSpecial/keyword/?validate=false',
	VALIDATE_EDIT_GOOGLE_AD: 'ads/{projectId}/editSpecial/ad/?validate=true',
	VALIDATE_SAVE_AD: 'ads/{projectId}/editSpecial/ad/?validate=false',
	VALIDATE_GOOGLE_ADS_RESOURCE: '/ads/{projectId}/edit/{type}?validate=true',
	SAVE_GOOGLE_ADS_RESOURCE: '/ads/{projectId}/edit/{type}?validate=false',
	GET_BIDDING_STRATEGIES_AND_BUDGETS_DATA:
		'/ads/{projectId}/getGoogleBiddingStrategiesAndBudgetsData',
	GET_GOOGLE_ADS_CAMPAIGNS: '/ads/{projectId}/adsVisualization/getCampaigns',
	GET_GROUPED_GOOGLE_ADS_CAMPAIGNS: '/ads/{projectId}/ads/grouped/{type}',
	GET_GOOGLE_ADS_AD_GROUPS_BY_CAMPAIGN:
		'/ads/{projectId}/adsVisualization/getAdGroups',
	GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS:
		'/ads/{projectId}/adsVisualization/getAllAdGroupsAndAssetGroups',
	GET_GOOGLE_ADS_BY_AD_GROUP_ID: '/ads/{projectId}/adsVisualization/getAds',
	GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID:
		'/ads/{projectId}/adsVisualization/getAdGroupsById',
	GET_GOOGLE_ADS_ALL_ADS: '/ads/{projectId}/adsVisualization/getAllAds',
	POST_CREATE_GOOGLE_ADS_CAMPAIGN: '/ads/{projectId}/ads/{type}',
	// GET_FACEBOOK_ADS_CAMPAIGNS: '/facebook/{projectId}/adsVisualization/campaign',
	// GET_FACEBOOK_ADS_ALL_AD_SETS: '/facebook/{projectId}/adsVisualization/adSets',
	// GET_FACEBOOK_ADS_ALL_ADS: '/facebook/{projectId}/adsVisualization/ad',
	GET_FACEBOOK_POSTS: 'facebook/{projectId}/getFacebookPosts',
	GET_FACEBOOK_ADS_CAMPAIGNS:
		'/facebook/{projectId}/adsVisualization/getData/campaign',
	GET_FACEBOOK_ADS_ALL_AD_SETS:
		'/facebook/{projectId}/adsVisualization/getData/adSets',
	GET_FACEBOOK_ADS_ALL_ADS: '/facebook/{projectId}/adsVisualization/getData/ad',
	GET_REPORT_DATA: '/reports/{projectId}/report/date/{date}',
	GET_REPORT_COMPONENT_DATA: '/reports/{projectId}/report/custom/component',
	GET_CUSTOM_REPORT_DATA: '/reports/{projectId}/report/custom/whole',
	GET_ECOMMERCE_REPORT_DATA: '/reports/{projectId}/ecommerceReport/{timeFrame}',
	GET_GOOGLE_MARKETING_REPORT_DATA:
		'/reports/{projectId}/googleMarketingReport/{timeFrame}',
	GET_FACEBOOK_MARKETING_REPORT_DATA:
		'/reports/{projectId}/facebookMarketingReport/{timeFrame}',
	GET_REPORT_DEFAULT_TEMPLATES: '/report-templates/{projectId}',
	GET_REPORT_TEMPLATES: '/report-templates/{projectId}/{companyId}',
	POST_REPORT_TEMPLATE: '/report-templates/{projectId}/{companyId}',
	POST_UPDATE_REPORT_TEMPLATE:
		'/report-templates/{projectId}/{companyId}/{templateId}',
	DELETE_REPORT_TEMPLATE:
		'/report-templates/{projectId}/{companyId}/{templateId}',
	GET_AUTOMATE_REPORT_DATA: '/report-automation/{token}',
	GET_GENERATED_MONTHLY_REPORT: '/report-automation/getReport/{projectId}',
	GET_SAVED_REPORT_FILE: '/report-automation/{projectId}/{reportId}',
	GET_LIST_OF_SELECTED_TEMPLATES:
		'/report-automation/{projectId}/selected-templates',
	UPDATE_SELECTED_TEMPLATE_SUBSCRIBERS:
		'/report-automation/{projectId}/selected-templates/{selectedTemplateId}',
	GET_SAVED_CUSTOM_REPORT_TEMPLATES:
		'/report-templates/{projectId}/custom/{companyId}',
	UPDATE_SAVED_CUSTOM_REPORT_TEMPLATE:
		'/report-templates/{projectId}/custom/{companyId}/{templateId}',

	CREATE_CUSTOM_REPORT_TEMPLATE:
		'/report-templates/{projectId}/custom/{companyId}',
	GET_GOOGLE_ANALYTIC_PAGE: '/google/{projectId}/analytics/pages?{query}',
	GET_GOOGLE_ANALYTIC_DATA: '/google/{projectId}/analytics?{query}',
	API_GOOGLE_ANALYTIC_VIEWS: '/google/{projectId}/analytics/views',
	API_GOOGLE_ANALYTIC_GA4_PROPERTY: '/google/{projectId}/analytics/property',
	API_GOOGLE_ANALYTIC_GA4_PROPERTIES:
		'/google/{projectId}/analytics/properties/ga4',
	GET_GOOGLE_ADS_DATA: '/ads/{projectId}/ads/{type}?{query}',
	API_GOOGLE_ADS_VIEWS: '/ads/{projectId}/ads/accounts',
	API_GOOGLE_ANALYTICS_GOALS: '/google/{projectId}/analytics/goal',
	API_GOOGLE_ANALYTICS_EVENTS: '/google/{projectId}/analytics/events',
	GET_GOOGLE_SEARCH_CONSOLE_DATA: '/google/{projectId}/searchconsole?{query}',
	API_GOOGLE_SEARCH_SITES: '/google/{projectId}/searchconsole/sites',
	GET_LINKEDIN_PAGES: '/linkedin/{projectId}/pages',
	CREATE_PROJECT: '/projects',
	CREATE_BULK_PROJECTS: '/projects/bulk',
	MODIFY_PROJECT: '/projects/{projectId}',
	UPDATE_PROJECT_TYPE: '/projects/{projectId}/type',
	GET_FACEBOOK_PAGES_INSIGHT:
		'/facebook/{projectId}/insights/{facebookMetric}?{query}',
	API_FACEBOOK_PAGES: '/facebook/{projectId}/pages',
	API_FACEBOOK_AD_MANAGERS: '/facebook/{projectId}/admanagers',
	EDIT_FACEBOOK_AD: 'facebook/{projectId}/adsVisualization/editData/{type}',
	GET_FACEBOOK_AD_CREATIVE:
		'facebook/{projectId}/adsVisualization/getAdCreativeData',
	EDIT_FACEBOOK_AD_CREATIVE:
		'facebook/{projectId}/adsVisualization/editAdCreative',
	GET_AD_GROUPS_BID_MODIFIER:
		'ads/{projectId}/adsVisualization/getAllAdGroupsBidModifier',
	UPDATE_ROAS_ALLOGATOR: 'ads/{projectId}/roasAllogator/{type}?{query}',
	GET_GOOGLE_ADS_EVENTS: 'ads/{projectId}/ads/events',
	UNSUBSCRIBE_DATA_SOURCE: '/datasource/{id}/sources/{datasourceType}',
	UPDATE_DATA_SOURCE: '/datasource/{projectId}/update',
	CHECK_GENERATION_STATUS: '/suggestions/{projectId}/check',
	SAVE_SITE_URL: '/audit/{projectId}',
	GET_AUDIT_SUMMARY_BY_ID: '/audit/{projectId}/{auditSummaryId}',
	GET_AUDITS_BY_PROJECT_ID: '/audit/{projectId}/getAllAuditSummaries',
	GET_PAGE_DETAILS_BY_AUDIT_ID:
		'/audit/{projectId}/{auditSummaryId}/pageDetails',
	GET_REDIRECT_CHAIN_BY_AUDIT_ID:
		'/audit/{projectId}/{auditSummaryId}/redirectChains',
	// GET_RESOURCES_BY_AUDIT_ID: '/audit/{projectId}/{auditSummaryId}/resources',
	GENERATE_SUGGESTION_BY_TYPE:
		'/suggestions/{projectId}/generate/{suggestionType}',
	GET_COMPETITOR_DATA: 'suggestions/{projectId}/competitor/analysis',
	GENERATE_SINGLE_SUGGESTION: '/suggestions/generate/single',
	GET_EXTERNAL_LINKS_FOR_SUGGESTION: '/suggestions/{projectId}/links',
	GENERATE_SUGGESTIONS_BY_KEYWORDS:
		'/suggestions/{projectId}/generate/seoSuggestionForASetOfKeywords',
	GET_SUGGESTION_BY_TYPE: '/suggestions/{projectId}/{suggestionType}', //frontend need to define the type when using this endpoint in the dashboard
	GET_SUGGESTION_COUNT: '/suggestions/{projectId}/{suggestionType}/count', //frontend need to define the type when using this endpoint in the dashboard
	GET_SUGGESTION_DATA_BY_ID:
		'/suggestions/{projectId}/{suggestionType}/{suggestionId}',
	GENERATE_TRACKING_DATA:
		'/suggestions/{projectId}/tracking/{suggestionType}/{suggestionId}',
	GENERATE_TRACKING_DATA_FOR_PREDICTOR:
		'/suggestions/{projectId}/tracking/searchVolume',
	UPDATE_SUGGESTION_STATUS:
		'/suggestions/{projectId}/{suggestionType}/{suggestionId}',
	UPDATE_SUGGESTION_ASSIGNEES: '/suggestions/{suggestionId}',
	UPDATE_TASK_ASSIGNEES: '/tasks/{taskId}',
	GENERATE_TASK_TRACKING_DATA: '/tasks/{projectId}/tracking/{taskId}',
	CHECK_PROJECTS_AMOUNT: '/users/projectCount',
	GET_PAYMENT_PLANS: '/payments/plans',
	SUBSCRIBE_PLAN: '/payments/subscribe',
	UNSUBSCRIBE_PLAN: '/payments/unsubscribe',
	FINALISE_PLAN: '/payments/finalise',
	GET_PAYMENT_PORTAL: '/payments/portal',
	GET_COUPON_CODE: 'payments/coupon',

	UPDATE_KEYWORD:
		'/keyword-ranking/{projectId}/{customerKeywordId}/updateCustomerKeyword', //not in use
	SAVE_NEW_KEYWORD:
		'/keyword-ranking/{projectId}/sendCustomerKeywordToRankTracking',
	BULK_SAVE_NEW_KEYWORD:
		'/keyword-ranking/{projectId}/sendBulkCustomerKeywordToRankTracking',
	DELETE_KEYWORD: '/keyword-ranking/{projectId}/{keywordId}',

	SAVE_KEYWORD_TABLE: '/keyword-ranking/{projectId}/keyword', //not in use
	POST_KEYWORD_DATA: '/keyword-ranking/{projectId}/getSuggestedKeyword',
	GET_KEYWORD_RANKING_FOR_CHECKING:
		'/keyword-ranking/{projectId}/getKeywordRankingForChecking',

	GET_KEYWORD_RANKING: '/keyword-ranking/{projectId}/getKeywordRanking',
	POST_KEYWORD_RANKING:
		'/keyword-ranking/{projectId}/sendCustomerKeywordToRankTracking',
	GET_RANKED_KEYWORDS: '/keyword-ranking/{projectId}/getRankedKeywordData',
	SAVE_KEYWORD_LABEL: '/keyword-ranking/{projectId}/saveKeywordLabel',
	GET_KEYWORD_LABEL_BY_PROJECT_ID:
		'/keyword-ranking/{projectId}/getKeywordLabelByProjectId',
	UPDATE_KEYWORD_LABEL_BY_ID:
		'/keyword-ranking/{projectId}/{labelId}/updateKeywordLabel',
	POST_KEYWORD_EXCEL: 'https://excel-dot-crono-291610.ey.r.appspot.com/files',
	GET_ALL_READY_MODEL: '/ai-content/finetune/{companyId}/getAllReadyModel',
	CREATE_FINETUNE_MODEL: '/ai-content/{projectId}/finetune/{sourceType}/create',
	LIST_ALL_FINETUNE_FILES: '/ai-content/{projectId}/finetune/listAllFiles',
	UPDATE_SAVED_FINETUNE_FILE: '/ai-content/{projectId}/finetune/updateFile',
	SEND_FINETUNE_FILE_TO_OPENAI: '/ai-content/{projectId}/finetune/sendFile',
	GET_SAVED_FINETUNE_FILE: '/ai-content/{projectId}/finetune/getFile',
	POST_AI_CONTENT: '/ai-content/{companyId}/{projectId}',
	GET_POST_AI_CONTENT: '/ai-content/{projectId}/facebookContent',
	GET_ALL_AI_ARTICLE: '/ai-content/{projectId}/ecommerceArticle/getAllArticle',
	GET_ALL_AI_CATEGORY_DESCRIPTION:
		'/ai-content/{projectId}/ecommerceArticle/getAllCategoryDescription',
	GET_AI_PRODUCT_DESCRIPTION:
		'/ai-content/{projectId}/ecommerceArticle/getArticleByName/{articleType}',
	CREATE_AI_BULK_GENERATION:
		'/ai-content/{projectId}/ecommerceArticle/createAiBulkGeneration/{generationType}',
	GET_ALL_BULK_GENERATION_BY_PROJECT_ID:
		'/ai-content/{projectId}/ecommerceArticle/getAllBulkGenerationByProjectId',
	TRANSLATE_CONTENT: '/ai-content/{projectId}/content/translate',
	GET_LINKEDIN_POSTS: '/linkedin/{projectId}/posts',
	POST_LINKEDIN_POST: '/linkedin/{projectId}/posts',
	POST_LINKEDIN_IMAGE_POST: '/linkedin/{projectId}/posts/imagePost/',
	UPDATE_LINKEDIN_POST: '/linkedin/{projectId}/posts/{postId}/{postType}',
	GET_LINKEDIN_POST_BY_ID: '/linkedin/{projectId}/posts/{postId}/{postType}',
	CREATE_LINKEDIN_IMAGE_SCHEDULED_POST:
		'/linkedin/{projectId}/posts/scheduled/imagePost/',
	CREATE_LINKEDIN_SCHEDULED_POST: '/linkedin/{projectId}/posts/scheduled/',
	UPDATE_LINKEDIN_SCHEDULED_POST:
		'/linkedin/{projectId}/posts/scheduled/{postId}/update/',
	GET_LINKEDIN_SCHEDULED_POST_BY_ID:
		'/linkedin/{projectId}/posts/scheduled/{postId}/',
	GET_LINKEDIN_SCHEDULED_POST_ALL: '/linkedin/{projectId}/posts/all/scheduled/',
	GET_STREAMING_TEXT: '/ai-content/streaming/text',
	SCHEDULE_FACEBOOK_POST: '/facebook/{projectId}/createSchedulePost/{type}',
	GET_INSTAGRAM_ACCOUNT: '/facebook/{projectId}/getInstagramAccount',
	PUBLISH_INSTAGRAM_POST: '/facebook/{projectId}/publishInstagramPost',
	SCHEDULE_INSTAGRAM_POST: '/facebook/{projectId}/scheduleInstagramPost',
	GET_FACEBOOK_POST_ATTACHMENTS:
		'/facebook/{projectId}/getFacebookPostAttachments',
	EDIT_SOME_FACEBOOK_POST: '/facebook/{projectId}/editFacebookPost',
	UPLOAD_FACEBOOK_PHOTO: '/facebook/{projectId}/uploadFacebookPhoto',
	EDIT_SCHEDULED_INSTAGRAM_POST:
		'facebook/{accountId}/{projectId}/editScheduledInstagramPost',
	UPLOAD_SCHEDULED_INSTAGRAM_PHOTO:
		'facebook/{projectId}/uploadScheduledInstagramPhoto',
	DELETE_FACEBOOK_POST: 'facebook/{projectId}/deletePost/facebook',
	DELETE_INSTAGRAM_SCHEDULED_POST: 'facebook/{projectId}/deletePost/instagram',
	POST_AI_HEADLINES: '/ai-content/{companyId}/{projectId}/headlines',
	POST_GOOGLE_ADS_HEADLINES:
		'/ai-content/{projectId}/googleAds/generateHeadlines',
	POST_GOOGLE_ADS_DESCRIPTIONS:
		'/ai-content/{projectId}/googleAds/generateDescriptions',
	GET_ALL_PROJECTS: '/projects',
	GET_PROJECT_BY_ID: '/projects/{projectId}',
	GET_PROJECT_USER: '/projects/{projectId}/{companyId}/getProjectUser',
	GET_WORDPRESS_ITEMS_BY_TYPE:
		'/wordpress/{projectId}/{wordPressContentType}/getAllContent',
	GET_WORDPRESS_ITEM_BY_ID:
		'/wordpress/{projectId}/{wordPressContentType}/getContentById/{contentId}',
	CREATE_WORDPRESS_ITEM_BY_TYPE:
		'/wordpress/{projectId}/{wordPressContentType}/createContent',
	UPDATE_WORDPRESS_ITEM_BY_ID:
		'/wordpress/{projectId}/{wordPressContentType}/updateContent/{contentId}',
	GET_QUOTA_LIMIT: '/quota-limitation/{companyId}/checkQuotaLimit/{feature}',
	CREATE_TASK: '/tasks/{projectId}',
	CREATE_CUSTOM_TASK: '/tasks/{projectId}/customTask',
	GET_ALL_TASKS: '/tasks/{projectId}',
	GET_TASK_BY_ID: '/tasks/{projectId}/{taskId}',
	UPDATE_TASK_BY_ID: '/tasks/{projectId}/{taskId}',
	DELETE_TASK_BY_ID: '/tasks/{projectId}/{taskId}',
	CREATE_COMPANY: '/company',
	UPDATE_COMPANY: '/company/{companyId}',
	UPDATE_COMPANY_USER: '/company/{companyId}/users/{userId}',
	CREATE_SUGGESTION_COMMENT: '/comments/{projectId}/suggestion/{itemId}',
	CREATE_TASK_COMMENT: '/comments/{projectId}/task/{itemId}',
	GET_ALL_COMMENTS: '/comments/{projectId}/{type}/{itemId}',
	UPDATE_COMMENT_BY_ID: '/comments/{projectId}/{commentId}',
	DELETE_COMMENT_BY_ID: '/comments/{projectId}/{commentId}',
	INVITE_USER_TO_COMPANY: 'company/{companyId}/sendInvitationToNewUser',
	GET_CONTENT_IN_BULK: '/ai-content/{companyId}/{projectId}/getContentInBulk',
	CREATE_RANK_PREDICTION: '/rank-predictor/{companyId}/{projectId}',
	GET_RANK_PREDICTION_BY_COMPANY_ID: '/rank-predictor/{companyId}/{projectId}',
	GET_RANK_PREDICTION_BY_ID:
		'/rank-predictor/{companyId}/{projectId}/{firebaseId}',
	CHECK_RANK_PREDICTION_BY_ID:
		'/rank-predictor/{companyId}/{projectId}/{firebaseId}/check',
	INVITE_CONTRIBUTOR_TO_PROJECT:
		'/project-contribution/{projectId}/sendProjectInvitation',
	GET_CONTRIBUTOR_LIST: '/project-contribution/{projectId}/getContributorList',
	UPDATE_CONTRIBUTOR_DATA:
		'/project-contribution/{projectId}/{contributorDataId}/updateContributorData',
	DELETE_CONTRIBUTOR_DATA:
		'/project-contribution/{projectId}/{contributorDataId}/deleteContributorData',
	GET_GOOGLE_ANALYTICS_COMPARISON_DATA:
		'/google/{projectId}/analytics/homePageComparisonData',
	AI_IMAGE_GENERATOR: '/ai-content/{projectId}/generate/image',
	GENERATE_SUBHEADINGS: '/ai-content/{projectId}/generate/subHeadings',
	GENERATE_BLOG_CONTENT: '/ai-content/{projectId}/generate/blogContent',
	CREATE_MERCHANT_PRODUCT_REQUEST:
		'/price-scalping/{companyId}/{projectId}/createMerchantProductRequest',
	GET_ALL_PRODUCT_PRICE: '/price-scalping/{projectId}/getAll',
	DELETE_PRODUCT_TRACKING: '/price-scalping/{projectId}/deleteOne/{id}',
	UPDATE_TRACKING_DATE: '/price-scalping/{projectId}/updateTrackingDate/{id}',
	GET_PRODUCT_REFERENCE: '/product-reference/{projectId}',
	SAVE_PRODUCT_REFERENCE: '/product-reference/{projectId}',
	GET_PRODUCT_LABEL: '/projects/{projectId}/custom-product-label',
	GET_SHOPIFY_PRODUCT_FEED: '/shopify/{projectId}/product-feed',
	SAVE_PRODUCT_LABEL: '/product-label/{projectId}/createProductLabel',
	DELETE_PRODUCT_LABEL: '/product-label/{projectId}/removeLabel/{id}',
	GET_PRODUCT_CATEGORY_TREE_MAP: '/product-tree-map/{projectId}/getCategoryMap',
	FETCH_DETAILS_DATA:
		'/product-tree-map/{projectId}/fetchMoreDetailsData/{type}',
	CREATE_SUPPLEMENTAL_FEED: 'shopify/{projectId}/product-feed/create-file',
	GET_SUPPLEMENTAL_FEED_FILES: 'shopify/{projectId}/product-feed/get-files',
	GET_SUPPLEMENTAL_FEED_FILE:
		'shopify/{projectId}/product-feed/get-single-file',
}

export const SETTING_PAGES = {
	PROFILE: 'Profile',
	PROJECTS: 'Projects',
	SUBSCRIPTION: 'Subscription',
	KEYWORD_PLANNER: 'Keyword Planner',
	KEYWORD_RANKING: 'Keyword Ranking',
	// AUDIT: 'Audit'
}

export const SUBSCRIPTION_PERIOD = {
	MONTHLY: 'Monthly',
	YEARLY: 'Yearly',
}
export enum EHIGHLIGHT {
	NONE,
	E_COMMERCE,
	CONVERSION_VALUE,
	CONVERSION_RATE,
	COUNTRY_SELECTION,
	LANGUAGE_SELECTION,
	PROJECT_URL,
	PROJECT_NAME,
	ESTIMATED_TIME,
	ANALYTICS_GOALS,
	NOTIFICATION,
	DELETE_PROJECT,
	DATASOURCES,
	GENERATE_TASKS,
	TASKS_TABS,
	TASKS_FILTERS,
	TASK_DESCRIPTION,
	IGNORE_BUTTON,
	DONE_BUTTON,
	ADD_IN_PROGRESS,
	SHOW_DETAILS,
	BACKGROUND,
	PROJECT_SETTINGS_NAVIGATION,
	SIDE_NAV,
	DASHBOARD_BUTTON,
	TASKS_BUTTON,
	TOOLS_BUTTON,
	CONTENT_TOOLS_BUTTON,
	PROJECT_SETTINGS_BUTTON,
	PROFILE_BUTTON,
	ADS_VISUALISATION_BUTTON,
	KEYWORD_PLANNER_BUTTON,
	COMPETITOR_EXPLORER_BUTTON,
	KEYWORD_RANKING_BUTTON,
	TECHNICAL_AUDIT_BUTTON,
	SUBSCRIPTION_BUTTON,
	COMPANY_BUTTON,
	CREATE_PROJECT_BUTTON,
	TUTORIAL_BUTTON,
	POST_SCHEDULER,
	WP_TOOLS,
	CONTENT_BULK_CREATION,
	RANK_PREDICTOR,
	GOOGLE_CHANGE_EVENTS,
	REPORT,
	PRICE_SCALPING,
	ECOMMERCE_FEED,
	MARKETING,
	CUSTOM_REPORTS,
	ECOMMERCE,
}

export const DATE_FORMAT = 'DD.MM.YYYY'
export const TAX_PERCENTAGE = 0.24

export const DATA_SOURCE = {
	ANALYTICS: 'analytics',
	ANALYTICS_GA4: 'analyticsGA4',
	FACEBOOK_PAGE: 'facebook_page',
	ADS: 'ads',
	SEARCH: 'search',
	FACEBOOK_AD_MANAGER: 'facebook_ad_manager',
	WORDPRESS: 'wordpress',
	LINKEDIN: 'linkedin',
	GOOGLE_MERCHANT: 'google_merchant',
	SHOPIFY: 'shopify',
}

export const DATA_SOURCE_OPTIONS = {
	ANALYTICS: 'analytics',
	ANALYTICS_GA4: 'analyticsGA4',
	FACEBOOK_PAGE: 'facebook_page',
	ADS: 'ads',
	SEARCH: 'search',
	FACEBOOK_AD_MANAGER: 'facebook_ad_manager',
	WORDPRESS: 'wordpress',
	ANALYTIC_GOALS: 'analyticsGoals',
	GA4_EVENTS: 'analyticsGA4Events',
	LINKEDIN_PAGES: 'linkedin_pages',
	GOOGLE_MERCHANT: 'google_merchant',
}

export const METRICS = {
	SESSIONS: 'sessions',
	USERS: 'users',
	PAGE_VIEWS: 'pageviews',
	GOAL_CONVERSION_RATE_ALL: 'goalConversionRateAll',
}
export const FACEBOOK_METRICS = {
	PAGE_FANS: 'page_fans',
	PAGE_REACTIONS: 'page_actions_post_reactions_total',
	PAGE_ACTIONS: 'page_total_actions',
	PAGE_FANS_ONLINE: 'page_fans_online',
	PAGE_FANS_GENDER_AGE: 'page_fans_gender_age',
}
export const FACEBOOK_PERIOD = {
	DAY: 'day',
	WEEK: 'week',
	MONTH: 'days_28',
	LIFETIME: 'lifetime',
}
export const DIMENSIONS = {
	DATE: 'date',
	DEVICE_CATEGORY: 'deviceCategory',
}

export const DASHBOARD_TABS = {
	HOME: 'home',
	OVERVIEW: 'overview',
	GOOGLE_ANALYTICS: 'google analytics',
	GOOGLE_ADS: 'google ads',
	FACEBOOK: 'facebook',
	GOOGLE_SEARCH: 'seo',
} as const

export type DashboardTabKeys = keyof typeof DASHBOARD_TABS
export type DashboardTabValues = (typeof DASHBOARD_TABS)[DashboardTabKeys]

export const CONTENT_CREATOR_COMPONENTS = {
	ARTICLE_CREATOR: 'article-creator',
	CONTENT_CREATOR: 'Article creator',
	GENERAL_CREATOR: 'General article creator',
	// GOOGLE_ADS_CONTENT_CREATOR: 'google-ads-content-creator',
	//CONTENT_CREATOR_LEGACY: 'content-creator-legacy',
	CONTENT_BULK_CREATION: 'bulk-creation',
	PRODUCT_DESCRIPTION_CREATOR: 'Product description creator',
	CATEGORY_DESCRIPTION_CREATOR: 'Category description creator',
	PRODUCT_ARTICLE_CREATOR: 'Product article creator',
	// CHAT: 'chat',
	DESCRIPTION_BULK: 'description-bulk',
}

export const CONTENT_CREATOR_TABS = {
	ARTICLE_CREATOR: 'article-creator',
	// CONTENT_CREATOR: 'content-creator',
	// GOOGLE_ADS_CONTENT_CREATOR: 'google-ads-content-creator',
	//CONTENT_CREATOR_LEGACY: 'content-creator-legacy',
	DESCRIPTION_CREATOR: 'description-creator',
	// CONTENT_BULK_CREATION: 'bulk-creation',
	// PRODUCT_DESCRIPTION_CREATOR: 'product-description-creator',
	// CATEGORY_DESCRIPTION_CREATOR: 'category-description-creator',
	// PRODUCT_ARTICLE_CREATOR: 'product-article-creator',
	DESCRIPTION_BULK: 'description-bulk',
	CHAT: 'chat',
}

export const CATEGORY_CREATOR_TABS = {
	CATEGORY_DESCRIPTION_CREATOR: 'category',
	PRODUCT_DESCRIPTION_CREATOR: 'product',
	TABLE_VIEW: 'table',
}

export const KEYWORD_PLANNER_TABS = {
	KEYWORD_PLANNER: 'keyword planner',
	KEYWORD_EXPLORER: 'keyword explorer',
}
export const KEYWORD_RANKING_TABS = {
	KEYWORD_RANKING: 'keyword ranking',
	KEYWORD_COMPETITOR_ANALYSIS: 'keyword competitor analysis',
}

export const DASHBOARD_ROUTES = {
	HOME: 'home',
	OVERVIEW: 'overview',
	GOOGLE_ANALYTICS: 'google-analytics',
	GOOGLE_ADS: 'google-ads',
	FACEBOOK: 'facebook',
	GOOGLE_SEARCH: 'seo',
} as const

export type DashboardRouteKeys = keyof typeof DASHBOARD_ROUTES
export type DashboardRouteValues = (typeof DASHBOARD_ROUTES)[DashboardRouteKeys]

export const DATASOURCE_ID = {
	FACEBOOK: 'facebookPageId',
	GOOGLE_ADS: 'googleAdsCustomerId',
	GOOGLE_ANALYTICS: 'googleAnalyticsViewId',
	GOOGLE_ANALYTICS_GA4: 'googleAnalyticsGa4WebPropertyId',
	GOOGLE_SEARCH: 'googleSearchToken',
	LINKEDIN_PAGE: 'linkedinPageId',
}

export const GOOGLE_SEARCH_DIMENSIONS = {
	PAGE: 'page',
	DATE: 'date',
	QUERY: 'query',
}

export const FB_PERIOD_SELECTION = {
	WEEK: 7,
	'2WEEKS': 14,
	MONTH: 30,
}

export const DASHBOA_PRIMARY_COLOR = '#182550'
export const CHART_COLORS = [
	'#61C9FB',
	'#172550',
	'#E9EEFC',
	DASHBOA_PRIMARY_COLOR,
]

export const EFFECTIVENESS_CHART_COLORS = [
	'#f44336',
	'#1877f2',
	'#ffc107',
	'#4caf50',
	'#BCEC4B',
]

export const OVERVIEW_TABLE_TAB = {
	ALL: 'All',
	BEST: 'Best',
	LATEST: 'Latest',
	IN_PROGRESS: 'In progress',
	DECLINED: 'Declined',
	COMPLETED: 'Completed',
}

export const SUGGESTION_STATUS = {
	PENDING: 0,
	ACTIVE: 1,
	ARCHIVED: 2,
}

export const CRO_GRAPH_VALUES = {
	USERS: { label: 'users', value: ['users'] },
	TIME_ON_PAGE: { label: 'avgSessionDuration', value: ['avgSessionDuration'] },
	BOUNCE_RATE: { label: 'bounceRate', value: ['bounceRate'] },
	CONVERSION_RATE: {
		label: 'transactionsPerSession',
		value: ['transactionsPerSession'],
	},
}

export const GOOGLE_ADS_GRAPH_VALUES = {
	AVERAGE_CPC: { label: 'average_cpc', value: ['average_cpc'] },
	CLICKS: { label: 'clicks', value: ['clicks'] },
	QUALITY_SCORE: {
		label: 'quality_score',
		value: [
			'quality_score',
			'totalQualityScore',
			'post_click_quality_score',
			'creative_quality_score',
		],
	},
	CONVERSIONS: { label: 'conversions', value: ['conversions'] },
	COST: { label: 'cost_micros', value: ['cost_micros'] },
}

export const GOOGLE_ADS_CAMPAIGN_GRAPH_VALUES = {
	AVERAGE_CPC: { label: 'average_cpc', value: ['average_cpc'] },
	CLICKS: { label: 'clicks', value: ['clicks'] },
	CONVERSIONS: { label: 'conversions', value: ['conversions'] },
	COST: { label: 'cost_micros', value: ['cost_micros'] },
}

export const GOOGLE_ADS_AD_GRAPH_VALUES = {
	AVERAGE_CPC: { label: 'average_cpc', value: ['average_cpc'] },
	CLICKS: { label: 'clicks', value: ['clicks'] },
	ROAS: { label: 'ROAS', value: ['ROAS'] },
	CONVERSIONS: { label: 'conversions', value: ['conversions'] },
}

export const GOOGLE_ADS_ACCOUNT_GRAPH_VALUES = {
	AVERAGE_CPC: { label: 'average_cpc', value: ['average_cpc'] },
	CLICKS: { label: 'clicks', value: ['clicks'] },
	IMPRESSION: { label: 'impressions', value: ['impressions'] },
	CONVERSIONS: { label: 'conversions', value: ['conversions'] },
	COST: { label: 'cost_micros', value: ['cost_micros'] },
}

export const SEO_GRAPH_VALUES = {
	POSITION: { label: 'position', value: ['position'] },
	CLICKS: { label: 'clicks', value: ['clicks'] },
	IMPRESSION: {
		label: 'impressions',
		value: ['impressions'],
	},
	CTR: { label: 'ctr', value: ['ctr'] },
}

export const NON_DATASOURCE_SEO_GRAPH_VALUES = {
	SEARCH_VOLUME: { label: 'search volume', value: ['searchVolume'] },
	POSITION: { label: 'position', value: ['position'] },
}

export const FACEBOOK_GRAPH_VALUES = {
	CPC: { label: 'cpc', value: ['cpc'] },
	CPM: {
		label: 'cpm',
		value: ['cpm'],
	},
	CPP: { label: 'cpp', value: ['cpp'] },
	BASIC_DATA: {
		label: 'basic_data',
		value: ['impressions', 'reach'],
	},
	SPEND: {
		label: 'spend',
		value: ['frequency', 'spend'],
	},
}

export const highlighter = (
	highlight: number,
	highlightValue: number,
): string => {
	if (highlight !== highlightValue) {
		return ''
	}
	switch (highlight) {
		case EHIGHLIGHT.DATASOURCES:
			return 'highlight-datasources'
		case EHIGHLIGHT.GENERATE_TASKS:
			return 'highlight-generate-tasks'
		case EHIGHLIGHT.PROJECT_NAME:
			return 'highlight-project-name'
		case EHIGHLIGHT.PROJECT_URL:
			return 'highlight-project-url'
		case EHIGHLIGHT.COUNTRY_SELECTION:
			return 'highlight-country-selection'
		case EHIGHLIGHT.LANGUAGE_SELECTION:
			return 'highlight-language-selection'
		case EHIGHLIGHT.E_COMMERCE:
			return 'highlight-ecommerce'
		case EHIGHLIGHT.CONVERSION_VALUE:
			return 'highlight-conversion-value'
		case EHIGHLIGHT.CONVERSION_RATE:
			return 'highlight-conversion-rate'
		case EHIGHLIGHT.ANALYTICS_GOALS:
			return 'highlight-get-analytics-goals'
		case EHIGHLIGHT.NOTIFICATION:
			return 'highlight-notification'
		case EHIGHLIGHT.DELETE_PROJECT:
			return 'highlight-delete-project'

		case EHIGHLIGHT.TASK_DESCRIPTION:
			return 'highlight-task-description'
		case EHIGHLIGHT.TASKS_TABS:
			return 'highlight-tasks-tabs'
		case EHIGHLIGHT.TASKS_FILTERS:
			return 'highlight-tasks-filters'
		case EHIGHLIGHT.IGNORE_BUTTON:
			return 'highlight-ignore-button'
		case EHIGHLIGHT.DONE_BUTTON:
			return 'highlight-done-button'
		case EHIGHLIGHT.ADD_IN_PROGRESS:
			return 'highlight-add-to-do-button'
		case EHIGHLIGHT.SHOW_DETAILS:
			return 'highlight-show-details-button'
		case EHIGHLIGHT.BACKGROUND:
			return 'highlight-background'
		case EHIGHLIGHT.PROJECT_SETTINGS_NAVIGATION:
			return 'highlight-project-settings-navigation'
		case EHIGHLIGHT.SIDE_NAV:
			return 'highlight-sidenav'
		case EHIGHLIGHT.DASHBOARD_BUTTON:
			return 'highlight-dashboard-button'
		case EHIGHLIGHT.TASKS_BUTTON:
			return 'highlight-tasks-button'
		case EHIGHLIGHT.TOOLS_BUTTON:
			return 'highlight-tools-button'
		case EHIGHLIGHT.ADS_VISUALISATION_BUTTON:
			return 'highlight-ads-visualisation-button'
		case EHIGHLIGHT.KEYWORD_PLANNER_BUTTON:
			return 'highlight-keyword-planner-button'
		case EHIGHLIGHT.COMPETITOR_EXPLORER_BUTTON:
			return 'highlight-competitor-explorer-button'
		case EHIGHLIGHT.KEYWORD_RANKING_BUTTON:
			return 'highlight-keyword-ranking-button'
		case EHIGHLIGHT.TECHNICAL_AUDIT_BUTTON:
			return 'highlight-technical-audit-button'
		case EHIGHLIGHT.PROJECT_SETTINGS_BUTTON:
			return 'highlight-project-settings-button'
		case EHIGHLIGHT.PROFILE_BUTTON:
			return 'highlight-profile-button'
		case EHIGHLIGHT.CREATE_PROJECT_BUTTON:
			return 'highlight-create-project-button'
		case EHIGHLIGHT.SUBSCRIPTION_BUTTON:
			return 'highlight-subscription-button'
		case EHIGHLIGHT.TUTORIAL_BUTTON:
			return 'highlight-tutorial-button'
		case EHIGHLIGHT.NONE:
			return ''
		default:
			return ''
	}
}

export enum ETUTORIAL_TYPES {
	CREATE_PROJECT,
	FIRST_TIME_USER,
	PROJECT_SETTINGS,
	TASKS,
}
export const GENERATE_SUGGESTION_STATUS = {
	NOT_GENERATED: 0,
	GENERATING: 1,
	GENERATED: 2,
}

export const DATASOURCE_STATUS = {
	ACTIVE: 1,
	INACTIVE: 0,
}

export const GoogleAdsPeriods = {
	LAST_7_DAYS: 'LAST_7_DAYS',
	LAST_14_DAYS: 'LAST_14_DAYS',
	LAST_30_DAYS: 'LAST_30_DAYS',
}

export const SuggestionStatus = {
	pending: 0,
	active: 1,
	archived: 2,
	done: 3,
	not_useful: 4,
}
export enum ESuggestionStatus {
	'PENDING' = 0,
	'ACTIVE' = 1,
	'ARCHIVED' = 2,
	'DONE' = 3,
	'NOT_USEFUL' = 4,
}

export type SuggestionStatusValue = 0 | 1 | 2 | 3 | 4
export type SuggestionStatusKey = 'pending' | 'active' | 'done' | 'not_useful'

export const TASK_TABS = {
	ACTIVE: 'to do',
	TODO: 'in_progress',
	RESOLVED: 'resolved',
	IGNORED: 'ignored',
}

export const GoogleAdsReportType = {
	report: 'report',
	adGroupAd: 'adGroupAd',
	campaign: 'campaign',
}

export const GoogleAdsScale = 1000000

export const microsKeys = [
	'cost_micros',
	'average_cpc',
	'hotel_average_lead_value_micros',
	'hotel_commission_rate_micros',
	'average_target_cpa_micros',
	'cost_per_conversion',
	'average_cost',
]

export const suggestionType = {
	all: 'all',
	cro: 'cro',
	ads: 'ads',
	some: 'some',
	seo: 'seo',
	task: 'task',
}

export const filteredSuggestionType = {
	noIgnore: 'noIgnore',
	cro: 'cro',
	ads: 'ads',
	some: 'some',
	seo: 'seo',
	task: 'task',
}

export const suggestionSubType = {
	seoLandingPage: 'seo_landing_page',
	seoKeywordOptimization: 'seo_keyword_optimization',
	adsRelevance: 'ads_relevance',
	adsQualityScore: 'ads_quality_score',
	adsNegativeKeyword: 'ads_negative_keyword',
	adsInaccurateKeyword: 'ads_inaccurate_keyword',
}

export const intervalTime = 10 * 1000

// Custom stylings for stripe card element
export const CARD_ELEMENT_OPTIONS = {
	hidePostalCode: true,
	style: {
		base: {
			color: '#32325d',
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: '#aab7c4',
			},
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a',
		},
	},
}

export const modalTableTypes: { [x: string]: ModalTableType } = {
	keywordPerformances: 'keywordPerformances',
	landingPageValue: 'landingPageValue',
	keywordValue: 'keywordValue',
	externalLinks: 'externalLinks',
}

export type ModalTableType =
	| 'keywordPerformances'
	| 'landingPageValue'
	| 'keywordValue'
	| 'externalLinks'

export type IRouteObj = {
	description: string
	route: string
	translationKey: string
	highlightKey: EHIGHLIGHT
}

type IToolsDropdown = {
	[key in keyof typeof tools]: IRouteObj
}

type IContentCreatorDropdown = {
	[key in keyof typeof content_creation]: IRouteObj
}

type INavigation = {
	[key: string]: IRouteObj
	// | IContentCreatorDropdown
	// | IToolsDropdown
}

export const reporting: INavigation = {
	ECOMMERCE: {
		description: 'eCommerce',
		route: ROUTER_PATH.REPORTS_ECOMMERCE,
		translationKey: 'sidenav.eCommerce',
		highlightKey: EHIGHLIGHT.ECOMMERCE,
	},
	MARKETING: {
		description: 'Marketing',
		route: ROUTER_PATH.REPORTS_MARKETING,
		translationKey: 'sidenav.marketing',
		highlightKey: EHIGHLIGHT.MARKETING,
	},
	CUSTOM_REPORTS: {
		description: 'Custom reports',
		route: ROUTER_PATH.REPORTS_TEMPLATES,
		translationKey: 'sidenav.custom reports',
		highlightKey: EHIGHLIGHT.CUSTOM_REPORTS,
	},
}

export const tools: INavigation = {
	ADS_VISUALISATION: {
		description: 'Ads visualization',
		route: ROUTER_PATH.ADS_VISUALISATION,
		translationKey: 'sidenav.ads visualization',
		highlightKey: EHIGHLIGHT.ADS_VISUALISATION_BUTTON,
	},
	CHANGE_EVENTS: {
		description: 'Google Change events',
		route: ROUTER_PATH.GOOGLE_CHANGE_EVENTS,
		translationKey: 'sidenav.ads change events',
		highlightKey: EHIGHLIGHT.GOOGLE_CHANGE_EVENTS,
	},
	KEYWORD_PLANNER: {
		description: 'Keyword planner',
		route: ROUTER_PATH.KEYWORD_PLANNER,
		translationKey: 'sidenav.keyword planner',
		highlightKey: EHIGHLIGHT.KEYWORD_PLANNER_BUTTON,
	},
	COMPETITOR_EXPLORER: {
		description: 'Competitor explorer',
		route: ROUTER_PATH.COMPETITOR_EXPLORER,
		translationKey: 'sidenav.competitor explorer',
		highlightKey: EHIGHLIGHT.COMPETITOR_EXPLORER_BUTTON,
	},
	KEYWORD_RANKING: {
		description: 'Keyword ranking',
		route: ROUTER_PATH.KEYWORD_RANKING,
		translationKey: 'sidenav.keyword ranking',
		highlightKey: EHIGHLIGHT.KEYWORD_RANKING_BUTTON,
	},
	TECHNICAL_AUDIT: {
		description: 'Technical audit',
		route: ROUTER_PATH.AUDIT,
		translationKey: 'sidenav.technical audit',
		highlightKey: EHIGHLIGHT.TECHNICAL_AUDIT_BUTTON,
	},
	ECOMMERCE_FEED: {
		description: 'eCommerece feed',
		route: ROUTER_PATH.ECOMMERCE_FEED,
		translationKey: 'sidenav.eCommerce feed',
		highlightKey: EHIGHLIGHT.ECOMMERCE_FEED,
	},
	// PRICE_SCALPER: {
	// 	description: 'Price scalper',
	// 	route: ROUTER_PATH.PRICE_SCALPING,
	// 	translationKey: 'sidenav.price scalping',
	// 	highlightKey: EHIGHLIGHT.PRICE_SCALPING,
	// },
	// REPORT: {
	// 	description: 'Report',
	// 	route: ROUTER_PATH.REPORTS_TEMPLATES,
	// 	translationKey: 'common.report',
	// 	highlightKey: EHIGHLIGHT.REPORT,
	// },
	// Hidden for now
	// RANK_PREDICTOR: {
	// 	description: 'Technical audit',
	// 	route: ROUTER_PATH.RANK_PREDICTION,
	// 	translationKey: 'sidenav.rank predictor',
	// 	highlightKey: EHIGHLIGHT.RANK_PREDICTOR,
	// },
}

export const content_creation = {
	AI_CONTENT_CREATOR: {
		description: 'Ai content creation',
		route: ROUTER_PATH.AI_CONTENT_CREATOR_BLOG_POST,
		translationKey: 'sidenav.content creator',
		highlightKey: EHIGHLIGHT.TECHNICAL_AUDIT_BUTTON,
	},
	// POST_SCHEDULER: {
	// 	description: 'Post scheduler',
	// 	route: ROUTER_PATH.POST_SCHEDULER_NEW_POST,
	// 	translationKey: 'sidenav.post scheduler',
	// 	highlightKey: EHIGHLIGHT.POST_SCHEDULER,
	// },
	// WP_TOOLS: {
	// 	description: 'WP TOOLS',
	// 	route: ROUTER_PATH.WP_CONTENT,
	// 	translationKey: 'sidenav.wp tools',
	// 	highlightKey: EHIGHLIGHT.WP_TOOLS,
	// },
	// CONTENT_BULK_CREATION: {
	// 	description: 'Content bulk creation',
	// 	route: ROUTER_PATH.AI_CONTENT_IN_BULK,
	// 	translationKey: 'topnav.content bulk creation',
	// 	highlightKey: EHIGHLIGHT.CONTENT_BULK_CREATION,
	// },
}

export const analyse: INavigation = {
	DASHBOARD: {
		description: 'Dashboard',
		route: ROUTER_PATH.HOME,
		translationKey: 'sidenav.dashboard',
		highlightKey: EHIGHLIGHT.DASHBOARD_BUTTON,
	},
	TASKS: {
		description: 'Tasks',
		route: ROUTER_PATH.TASKS,
		translationKey: 'sidenav.tasks',
		highlightKey: EHIGHLIGHT.TASKS_BUTTON,
	},
	// REPORT: {
	// 	description: 'Report',
	// 	route: ROUTER_PATH.REPORTS_TEMPLATES,
	// 	translationKey: 'common.reporting',
	// 	highlightKey: EHIGHLIGHT.TASKS_BUTTON,
	// },
}

export const manage: INavigation = {
	PROJECT_SETTINGS: {
		description: 'Project settings',
		route: ROUTER_PATH.PROJECT_SETTINGS,
		translationKey: 'sidenav.project settings',
		highlightKey: EHIGHLIGHT.PROJECT_SETTINGS_BUTTON,
	},
}

export const settings = {
	PROFILE: {
		description: 'Profile',
		route: ROUTER_PATH.PROFILE,
		translationKey: 'sidenav.profile',
		highlightKey: EHIGHLIGHT.PROFILE_BUTTON,
	},
	SUBSCRIPTION: {
		description: 'Subscription',
		route: ROUTER_PATH.SUBSCRIPTION,
		translationKey: 'sidenav.subscription',
		highlightKey: EHIGHLIGHT.SUBSCRIPTION_BUTTON,
	},
	COMPANY: {
		description: 'Company',
		route: ROUTER_PATH.COMPANY,
		translationKey: 'sidenav.company',
		highlightKey: EHIGHLIGHT.COMPANY_BUTTON,
	},
	LOG_OUT: {
		description: 'Log out',
		route: ROUTER_PATH.LOGIN,
		translationKey: 'sidenav.sign out',
		highlightKey: EHIGHLIGHT.NONE,
	},
}

export enum ECustomReportComponents {
	LINE_CHART = 'Line Chart',
	PIE_CHART = 'Pie Chart',
	BAR_CHART = 'Bar Chart',
	BOX = 'Box Element',
	TEXT = 'Text Element',
	WRAPPER = 'Wrapper Element',
	IMAGE = 'Image Element',
	TABLE = 'Table Element',
}

export type IReportComponent = {
	id: string
	name: ECustomReportComponents
	placement: {
		top: number
		left: number
		width: number
		height: number
	}
	dataSource?: EDatasourceType
	metrics?: MetricsType
	dimensions?: DimensionsType
	from?: string
	to?: string
	textStyles?: Record<string, any>
	containerStyles?: Record<string, any>
	period: null | ECustomReportPeriods
	comparison?: {
		from: string | null
		to: string | null
		period: null | ECustomReportPeriods
	}
	segment: ECustomReportSegments
	filters?: string[]
	title?: string
	campaigns?: string[]
}

export enum ECustomReportPeriods {
	'LAST_28_DAYS' = 'LAST_28_DAYS',
	'LAST_14_DAYS' = 'LAST_14_DAYS',
	'LAST_7_DAYS' = 'LAST_7_DAYS',
	'LAST_3_MONTHS' = 'LAST_3_MONTHS',
	'LAST_6_MONTHS' = 'LAST_6_MONTHS',
	'LAST_12_MONTHS' = 'LAST_12_MONTHS',
	'CUSTOM' = 'CUSTOM',
}
export enum ECustomReportSegments {
	'date' = 'date',
	'week' = 'week',
	'month' = 'month',
}

export enum EDatasourceType {
	Ads = 'ads',
	Analytics = 'analytics',
	AnalyticsGA4 = 'analyticsGA4',
	GSC = 'gsc',
	Facebook = 'facebook',
}

export enum EOpenAIModelByFeature {
	magicWrite = 'magicWrite',
	contentCreator = 'contentCreator',
	postScheduler = 'postScheduler',
	productDescription = 'productDescription',
}

export enum EGoogleAnalyticMetrics {
	sessions = 'sessions',
	pageViews = 'pageviews',
	pageValue = 'pageValue',
	goalConversionRateAll = 'goalConversionRateAll',
	goalConversionValueAll = 'goalValueAll',
	goalCompletionsAll = 'goalCompletionsAll',
	users = 'users',
	averageOrderValue = 'revenuePerTransaction',
	CTR = 'CTR',
	adClicks = 'adClicks',
	eCommerceConversionRate = 'transactionsPerSession',
	bounceRate = 'bounceRate',
	transactionRevenue = 'transactionRevenue',
	transactions = 'transactions',
	impressions = 'impressions',
	averageSessionDuration = 'avgSessionDuration',
	uniquePurchases = 'uniquePurchases',
	newUsers = 'newUsers',
	pageLoadTime = 'pageLoadTime',
}

export const GoogleAnalyticsGa4Metrics = {
	sessions: { name: 'sessions' },
	pageViews: { name: 'pageviews', expression: 'screenPageViews' },
	pageValue: { name: 'pageValue', expression: 'totalRevenue/sessions' },
	// goalConversionRateAll = 'goalConversionRateAll',
	// goalConversionValueAll = 'goalValueAll',
	// goalCompletionsAll = 'goalCompletionsAll',
	users: { name: 'users', expression: 'totalUsers' },
	averageOrderValue: {
		name: 'averageOrderValue',
		expression: 'totalRevenue/totalPurchasers',
	},
	CTR: { name: 'CTR', expression: 'publisherAdClicks/publisherAdImpressions' },
	conversions: { name: 'conversions' },
	adClicks: { name: 'adClicks', expression: 'publisherAdClicks' },
	eCommerceConversionRate: {
		name: 'transactionsPerSession',
		expression: 'purchaserConversionRate',
	},
	transactionsPerSession: {
		name: 'transactionsPerSession',
		expression: 'purchaserConversionRate',
	},
	bounceRate: { name: 'bounceRate' },
	transactionRevenue: {
		name: 'transactionRevenue',
		expression: 'purchaseRevenue/ecommercePurchases',
	},
	revenuePerTransaction: {
		name: 'transactionRevenue',
		expression: 'purchaseRevenue/ecommercePurchases',
	},
	transactions: { name: 'transactions', expression: 'ecommercePurchases' },
	impressions: { name: 'impressions', expression: 'publisherAdImpressions' },
	averageSessionDuration: {
		name: 'avgSessionDuration',
		expression: 'userEngagementDuration/sessions',
	},
	uniquePurchases: {
		name: 'uniquePurchases',
		expression: 'ecommercePurchases',
	},
	newUsers: { name: 'newUsers' },
	eventCount: { name: 'eventCount' },
	eventValue: { name: 'eventValue' },
	eventConversionValue: {
		name: 'transactionRevenue',
		expression: 'eventValue/eventCount',
	},
	eventConversionRate: {
		name: 'transactionsPerSession',
		expression: 'eventCount/sessions',
	},
	// pageLoadTime: 'pageLoadTime',
}

export type GoogleAnalyticsGa4MetricsKeys =
	keyof typeof GoogleAnalyticsGa4Metrics
export type GoogleAnalyticsGa4MetricsValue =
	(typeof GoogleAnalyticsGa4Metrics)[GoogleAnalyticsGa4MetricsKeys]

export enum GoogleAdsMetrics {
	clicks = 'metrics.clicks',
	impressions = 'metrics.impressions',
	ctr = 'metrics.ctr',
	average_cost = 'metrics.average_cost',
	average_cpc = 'metrics.average_cpc',
	average_cpe = 'metrics.average_cpe',
	average_cpm = 'metrics.average_cpm',
	average_cpv = 'metrics.average_cpv',
	absolute_top_impression_percentage = 'metrics.absolute_top_impression_percentage',
	active_view_cpm = 'metrics.active_view_cpm',
	active_view_ctr = 'metrics.active_view_ctr',
	active_view_impressions = 'metrics.active_view_impressions',
	active_view_measurability = 'metrics.active_view_measurability',
	active_view_measurable_cost_micros = 'metrics.active_view_measurable_cost_micros',
	active_view_measurable_impressions = 'metrics.active_view_measurable_impressions',
	active_view_viewability = 'metrics.active_view_viewability',
	all_conversions = 'metrics.all_conversions',
	all_conversions_by_conversion_date = 'metrics.all_conversions_by_conversion_date',
	all_conversions_from_click_to_call = 'metrics.all_conversions_from_click_to_call',
	all_conversions_from_directions = 'metrics.all_conversions_from_directions',
	all_conversions_from_interactions_rate = 'metrics.all_conversions_from_interactions_rate',
	all_conversions_from_interactions_value_per_interaction = 'metrics.all_conversions_from_interactions_value_per_interaction',
	all_conversions_from_menu = 'metrics.all_conversions_from_menu',
	all_conversions_from_order = 'metrics.all_conversions_from_order',
	all_conversions_from_other_engagement = 'metrics.all_conversions_from_other_engagement',
	all_conversions_from_store_visit = 'metrics.all_conversions_from_store_visit',
	all_conversions_from_store_website = 'metrics.all_conversions_from_store_website',
	all_conversions_value = 'metrics.all_conversions_value',
	all_conversions_value_by_conversion_date = 'metrics.all_conversions_value_by_conversion_date',
	all_conversions_value_per_cost = 'metrics.all_conversions_value_per_cost',
	auction_insight_search_absolute_top_impression_percentage = 'metrics.auction_insight_search_absolute_top_impression_percentage',
	auction_insight_search_impression_share = 'metrics.auction_insight_search_impression_share',
	auction_insight_search_outranking_share = 'metrics.auction_insight_search_outranking_share',
	auction_insight_search_overlap_rate = 'metrics.auction_insight_search_overlap_rate',
	auction_insight_search_position_above_rate = 'metrics.auction_insight_search_position_above_rate',
	auction_insight_search_top_impression_percentage = 'metrics.auction_insight_search_top_impression_percentage',

	average_page_views = 'metrics.average_page_views',
	average_time_on_site = 'metrics.average_time_on_site',
	benchmark_average_max_cpc = 'metrics.benchmark_average_max_cpc',
	benchmark_ctr = 'metrics.benchmark_ctr',
	biddable_app_install_conversions = 'metrics.biddable_app_install_conversions',
	biddable_app_post_install_conversions = 'metrics.biddable_app_post_install_conversions',
	bounce_rate = 'metrics.bounce_rate',

	combined_clicks = 'metrics.combined_clicks',
	combined_clicks_per_query = 'metrics.combined_clicks_per_query',
	combined_queries = 'metrics.combined_queries',
	content_budget_lost_impression_share = 'metrics.content_budget_lost_impression_share',
	content_impression_share = 'metrics.content_impression_share',
	content_rank_lost_impression_share = 'metrics.content_rank_lost_impression_share',
	conversion_last_conversion_date = 'metrics.conversion_last_conversion_date',
	conversion_last_received_request_date_time = 'metrics.conversion_last_received_request_date_time',
	conversions = 'metrics.conversions',
	conversions_by_conversion_date = 'metrics.conversions_by_conversion_date',
	conversions_from_interactions_rate = 'metrics.conversions_from_interactions_rate',
	conversions_from_interactions_value_per_interaction = 'metrics.conversions_from_interactions_value_per_interaction',
	conversions_value = 'metrics.conversions_value',
	conversions_value_by_conversion_date = 'metrics.conversions_value_by_conversion_date',
	conversions_value_per_cost = 'metrics.conversions_value_per_cost',
	cost_micros = 'metrics.cost_micros',
	cost_per_all_conversions = 'metrics.cost_per_all_conversions',
	cost_per_conversion = 'metrics.cost_per_conversion',
	cost_per_current_model_attributed_conversion = 'metrics.cost_per_current_model_attributed_conversion',
	cross_device_conversions = 'metrics.cross_device_conversions',

	current_model_attributed_conversions = 'metrics.current_model_attributed_conversions',
	current_model_attributed_conversions_from_interactions_rate = 'metrics.current_model_attributed_conversions_from_interactions_rate',
	current_model_attributed_conversions_from_interactions_value_per_interaction = 'metrics.current_model_attributed_conversions_from_interactions_value_per_interaction',
	current_model_attributed_conversions_value = 'metrics.current_model_attributed_conversions_value',
	current_model_attributed_conversions_value_per_cost = 'metrics.current_model_attributed_conversions_value_per_cost',
	engagement_rate = 'metrics.engagement_rate',
	engagements = 'metrics.engagements',
	gmail_forwards = 'metrics.gmail_forwards',
	gmail_saves = 'metrics.gmail_saves',
	gmail_secondary_clicks = 'metrics.gmail_secondary_clicks',
	historical_creative_quality_score = 'metrics.historical_creative_quality_score',
	historical_landing_page_quality_score = 'metrics.historical_landing_page_quality_score',
	historical_quality_score = 'metrics.historical_quality_score',
	historical_search_predicted_ctr = 'metrics.historical_search_predicted_ctr',
	hotel_average_lead_value_micros = 'metrics.hotel_average_lead_value_micros',
	hotel_commission_rate_micros = 'metrics.hotel_commission_rate_micros',
	hotel_eligible_impressions = 'metrics.hotel_eligible_impressions',
	hotel_expected_commission_cost = 'metrics.hotel_expected_commission_cost',
	hotel_price_difference_percentage = 'metrics.hotel_price_difference_percentage',

	impressions_from_store_reach = 'metrics.impressions_from_store_reach',
	interaction_event_types = 'metrics.interaction_event_types',
	interaction_rate = 'metrics.interaction_rate',
	interactions = 'metrics.interactions',
	invalid_click_rate = 'metrics.invalid_click_rate',
	invalid_clicks = 'metrics.invalid_clicks',
	message_chat_rate = 'metrics.message_chat_rate',
	message_chats = 'metrics.message_chats',
	message_impressions = 'metrics.message_impressions',
	mobile_friendly_clicks_percentage = 'metrics.mobile_friendly_clicks_percentage',
	optimization_score_uplift = 'metrics.optimization_score_uplift',
	optimization_score_url = 'metrics.optimization_score_url',
	organic_clicks = 'metrics.organic_clicks',
	organic_clicks_per_query = 'metrics.organic_clicks_per_query',
	organic_impressions = 'metrics.organic_impressions',
	organic_impressions_per_query = 'metrics.organic_impressions_per_query',
	organic_queries = 'metrics.organic_queries',
	percent_new_visitors = 'metrics.percent_new_visitors',
	phone_calls = 'metrics.phone_calls',
	phone_impressions = 'metrics.phone_impressions',
	phone_through_rate = 'metrics.phone_through_rate',
	relative_ctr = 'metrics.relative_ctr',
	search_absolute_top_impression_share = 'metrics.search_absolute_top_impression_share',
	search_budget_lost_absolute_top_impression_share = 'metrics.search_budget_lost_absolute_top_impression_share',
	search_budget_lost_impression_share = 'metrics.search_budget_lost_impression_share',
	search_budget_lost_top_impression_share = 'metrics.search_budget_lost_top_impression_share',
	search_click_share = 'metrics.search_click_share',
	search_exact_match_impression_share = 'metrics.search_exact_match_impression_share',
	search_impression_share = 'metrics.search_impression_share',
	search_rank_lost_absolute_top_impression_share = 'metrics.search_rank_lost_absolute_top_impression_share',
	search_rank_lost_impression_share = 'metrics.search_rank_lost_impression_share',
	search_rank_lost_top_impression_share = 'metrics.search_rank_lost_top_impression_share',
	search_top_impression_share = 'metrics.search_top_impression_share',
	sk_ad_network_conversions = 'metrics.sk_ad_network_conversions',
	speed_score = 'metrics.speed_score',
	top_impression_percentage = 'metrics.top_impression_percentage',
	valid_accelerated_mobile_pages_clicks_percentage = 'metrics.valid_accelerated_mobile_pages_clicks_percentage',
	value_per_all_conversions = 'metrics.value_per_all_conversions',
	value_per_all_conversions_by_conversion_date = 'metrics.value_per_all_conversions_by_conversion_date',
	value_per_conversion = 'metrics.value_per_conversion',
	value_per_conversions_by_conversion_date = 'metrics.value_per_conversions_by_conversion_date',
	value_per_current_model_attributed_conversion = 'metrics.value_per_current_model_attributed_conversion',
	video_quartile_p100_rate = 'metrics.video_quartile_p100_rate',
	video_quartile_p25_rate = 'metrics.video_quartile_p25_rate',
	video_quartile_p50_rate = 'metrics.video_quartile_p50_rate',
	video_quartile_p75_rate = 'metrics.video_quartile_p75_rate',
	video_view_rate = 'metrics.video_view_rate',
	video_views = 'metrics.video_views',
	view_through_conversions = 'metrics.view_through_conversions',
}

export enum EFacebookFields {
	ActionValues = 'action_values',
	Actions = 'actions',
	Name = 'name',
	QualityRanking = 'quality_ranking',
	ConversionValues = 'conversion_values',
	ConversionRateRanking = 'conversion_rate_ranking',
	EngagementRateRanking = 'engagement_rate_ranking',
	// AdName = 'ad_name',
	AdId = 'ad_id',
	Impressions = 'impressions',
	Reach = 'reach',
	Spend = 'spend',
	CampaignId = 'campaign_id',
	// CampaignName = 'campaign_name',
	Clicks = 'clicks',
	Frequency = 'frequency',
	Conversions = 'conversions',
	CostPerConversion = 'cost_per_conversion',
	CPC = 'cpc',
	CPM = 'cpm',
	CPP = 'cpp',
	ConvertedProductValue = 'converted_product_value',
	PurchaseRoas = 'purchase_roas',
	AdsetId = 'adset_id',
	// AdsetName = 'adset_name',
	Creative = 'creative',
	AssetFeedSpec = 'asset_feed_spec',
	ObjectStorySpec = 'object_story_spec',
	Attachments = 'attachments',
	ScheduledPublishTime = 'scheduled_publish_time',
	Message = 'message',
	AccountId = 'account_id',
	AccountName = 'account_name',
}

export enum EContentCreatorSteps {
	TOPIC = 'TOPIC',
	CONTENT_TYPE = 'CONTENT_TYPE',
	CONTENT_SUBJECT = 'CONTENT_SUBJECT',
	KEYWORDS = 'KEYWORDS',
	FINAL = 'FINAL',
}

export type MetricsType =
	| EGoogleAnalyticMetrics[]
	| GoogleAnalyticsGa4MetricsValue[]
	| GoogleAdsMetrics[]
	| EFacebookFields[]
export type MetricsKeysType =
	| EGoogleAnalyticMetrics[]
	| GoogleAnalyticsGa4MetricsValue['name'][]
	| GoogleAdsMetrics[]
	| EFacebookFields[]

export type MetricsValue =
	| EGoogleAnalyticMetrics
	| GoogleAnalyticsGa4MetricsValue
	| GoogleAdsMetrics
	| EFacebookFields

export type ReportTemplateCustomProperty = {
	salesProspectData?: {
		firstSection?: string
		secondSection?: string
		thirdSection?: string
	}
}

export enum EGoogleAdsDimensions {
	campaignName = 'campaignName',
	adGroup = 'adGroup',
	date = 'segments.date',
	conversionAction = 'segments.keyword.info.text',
	keywordMatchType = 'segments.keyword.info.match_type',
}
export enum EGoogleAnalyticDimensions {
	date = 'date',
	deviceCategory = 'deviceCategory',
	pagePath = 'pagePath',
	keyword = 'keyword',
	landingPagePath = 'landingPagePath',
	source = 'source',
}

export enum EGoogleGSCMetrics {
	clicks = 'clicks',
	impressions = 'impressions',
	ctr = 'ctr',
	position = 'position',
}

export enum EGoogleSearchDimension {
	date = 'date',
	page = 'page',
	query = 'query',
}

export const GoogleAnalyticsGa4Dimensions = {
	date: { name: 'date' },
	source: { name: 'sessionSource' },
	deviceCategory: { name: 'deviceCategory' },
	landingPagePath: { name: 'landingPage' },
	pagePath: { name: 'pagePath' },
	eventName: { name: 'eventName' },
}

export type GoogleAnalyticsGa4DimensionsKeys =
	keyof typeof GoogleAnalyticsGa4Dimensions
export type GoogleAnalyticsGa4DimensionsValue =
	(typeof GoogleAnalyticsGa4Dimensions)[GoogleAnalyticsGa4DimensionsKeys]

export type DimensionsType =
	| EGoogleAnalyticDimensions[]
	| EGoogleSearchDimension[]
	| GoogleAnalyticsGa4DimensionsValue[]

export interface IReportTemplateItem {
	id: number
	name: string
	component: EReportComponents
}

export enum EFacebookDimensions {
	campaign = 'campaign_name',
	adSet = 'adset_name',
	ad = 'ad_name',
	date = 'date',
}

export type IReportPage = {
	items: IReportComponent[]
	pageNumber: number
	title?: string
	logo?: string
	color?: {
		primary: string
		secondary: string
	}
	theme: ReportTemplateCustom['theme']
}

export type ReportTemplateItemType = IReportTemplateItem | IReportPage

export interface ReportTemplateBase {
	id?: string
	projectId?: number
	name: string
	default?: boolean
	logoUrl?: string
	primary?: string | any
	secondary?: string | any
	customProperties?: ReportTemplateCustomProperty
	// items: ReportTemplateItemType[]
}

export interface ReportTemplate extends ReportTemplateBase {
	items: IReportTemplateItem[]
	type: null
	layout: CustomReportLayout
}

export type CustomReportLayout = {
	name: string
	width: string
	height: string
}
export interface ReportTemplateCustom extends ReportTemplateBase {
	items: IReportPage[]
	theme: {
		componentStyles: Record<string, any>
		textStyles: Record<string, any>
		pageStyles: Record<string, any>
	}
	layout: CustomReportLayout
	type: 'CUSTOM'
}

export enum EMarketingReportTimeFrame {
	WEEKLY = 'weekly',
	MONTHLY = 'monthly',
	LAST_30_DAYS = 'last_30_days',
}

export interface MarketingTableData {
	name: string
	paidClicks: string
	impressions: string
	ctr: string
	cpc: string
	orders: string
	costPerOrder: string
	conversionRate: string
	adSpend: string
	roas: string
	revenue: string
}

export interface EcommerceTableData {
	name: string
	totalSales: string
	orders: string
	adSpend: string
	grossProfit: string
	roas: string
	contributionMargin: string
	netProfit: string
	mer: string
}

export type ReportTemplatesType = ReportTemplate | ReportTemplateCustom

export type ReportNumbers = {
	conversion: number
	facebookMarketingThisMonth: number
	facebookMarketingLastMonth: number
	facebookMarketingLastYearThisMonth: number
	googlePPCThisMonth: number
	googlePPCLastMonth: number
	googlePPCLastYearSameTime: number
	rate: number
	conversionRate: number
	transactions: number
}

export type ReportParams = {
	year: number
	month: number
	primary: string
	secondary: string
}

export type ReportGoogleAdsData = {
	ad_group: GoogleAdsAdGroupReportData
	ad_group_ad: {
		ad: GoogleAdsAdGroupAdReportData
	}
	resource_name: string
	metrics: GoogleAdsAdGroupMetricsReportData
}
export type ReportFacebookCampaignData = {
	campaign_id: string
	campaign_name: string
	clicks: string
	conversions?: ReportFacebookCampaignActionAndValue[]
	cost_per_conversion?: ReportFacebookCampaignActionAndValue[]
	actions?: ReportFacebookCampaignActionAndValue[]
	action_values?: ReportFacebookCampaignActionAndValue[]
	date_start: string
	date_stop: string
	impressions: string
	reach: string
	spend: string
	cpc: string
	purchase_roas: ReportFacebookCampaignActionAndValue[]
}

type ReportFacebookCampaignActionAndValue = {
	action_type: string
	value: string
}

export type GoogleAdsAdGroupReportData = {
	name: string
	resource_name: string
}
export type GoogleAdsAdGroupAdReportData = {
	expanded_text_ad: null | ExpandedTextAdType
	resource_name: string
}
export type ExpandedTextAdType = {
	description: string
	description2: string
	headline_part1: string
	headline_part2: string
	headline_part3: string
}
export type GoogleAdsAdGroupMetricsReportData = {
	average_cost: number
	clicks: number
	conversions_from_interactions_rate: number
	cost_micros: number
	cost_per_conversion: number
	average_cpc: number
	impressions: number
	interaction_rate: number
	conversions: number
	conversions_value: number
}

export type ReportNumbersConversions = {
	conversion: number
	conversionRate: number
	sessions: number
	transactions: number
}
export type ReportNumbersTransactions = {
	transactions: number
	averageOrderValue: number
}
export type ReportGoogleSearchData = {
	clicks: number
	ctr: number
	impressions: number
	page: string
	position: number
}
export type ReportGoogleSearchTermData = {
	ctr: number
	impressions: number
	position: number
	query: string
}
export type ReportGoogleAnalyticsData = {
	CTR: string
	adClicks: string
	date: string
	goalCompletionsAll: string
	goalConversionRateAll: string
	impressions: string
	pageValue: string
	source: string
	transactionRevenue: string
	transactionsPerSession: string
	uniquePurchases: string
	users: string
	newUsers: string
	bounceRate: string
	pageLoadTime: string
	deviceCategory: string
}

export type ReportActivitiesType = {
	ads: number
	cro: number
	seo: number
	some: number
}

export type ReportResponseType = {
	conversionThisMonth: ReportNumbersConversions
	conversionLastMonth: ReportNumbersConversions
	conversionLastYearThisMonth: ReportNumbersConversions
	conversionComparisonWithLastMonth: ReportNumbersConversions
	conversionComparisonWithLastYear: ReportNumbersConversions
	salesThisMonth: ReportNumbersTransactions
	salesLastYearSameTime: ReportNumbersTransactions
	activitiesThisMonth: ReportActivitiesType
	numbers: ReportNumbers
	facebookAdsDataThisMonth: ReportFacebookCampaignData[]
	facebookAdsDataLastMonth: ReportFacebookCampaignData[]
	facebookAdsDataLastYearThisMonth: ReportFacebookCampaignData[]
	googleAdsDataThisMonth: ReportGoogleAdsData[]
	googleAdsDataLastMonth: ReportGoogleAdsData[]
	googleAdsDataLastYearSameTime: ReportGoogleAdsData[]
	googleAnalyticsForThisMonth: ReportGoogleAnalyticsData[]
	googleAnalyticsForLastMonth: ReportGoogleAnalyticsData[]
	googleAnalyticsForLastYearSameMonth: ReportGoogleAnalyticsData[]
	googleSearchData: ReportGoogleSearchData[]
	googleSearchDataLastMonth: ReportGoogleSearchData[]
	googleSearchTermData: ReportGoogleSearchTermData[]
	googleSearchTermDataLastMonth: ReportGoogleSearchTermData[]
	nextMonthSuggestions: []
	suggestionsDoneThisYear: any[]
	activitiesNextMonth: ReportActivitiesType
	doneSuggestions: []
	salesProspectSuggestions: []
	doneSuggestionData: {
		keywordData: { keyword: string; items: any[] }[]
		searchVolumeData: { keyword: string; search_volume: number }[]
	}
	doneSuggestionSearchData: ReportGoogleSearchData & { query: string }[]
	currentDoneSuggestionSearchData: ReportGoogleSearchData & { query: string }[]
}

export enum EFeatureNameWithQuota {
	aiContent = 'aiContent',
	keywordPlanner = 'keywordPlanner',
	priceScalping = 'priceScalping',
}

export enum EDetailType {
	'WARNING' = 'WARNING',
	'ERROR' = 'ERROR',
}

export enum ESuggestionType {
	seo = 'seo',
	ads = 'ads',
	cro = 'cro',
	some = 'some',
	all = 'all',
	guide = 'GUIDE',
	custom = 'CUSTOM',
}

export enum EOperators {
	'<' = '<',
	'>' = '>',
	'=' = '=',
	'>=' = '>=',
	'<=' = '<=',
}

export enum EGoogleAdsAvailableValues {
	'PPC' = 'pricePerConversion',
	'AVERAGE_CPC' = 'averageCPC',
	'ROAS' = 'ROAS',
	'CONVERSION' = 'conversions',
}
export enum EFacebookAdsAvailableValues {
	'ROAS' = 'ROAS',
	'CPC' = 'CPC',
	'CONVERSIONS' = 'conversions',
	'COST_PER_CONVERSION' = 'costPerConversion',
	'SPEND' = 'spend',
}
export enum EeCommerceShopGoogleAdsAvailableValues {
	'POAS' = 'POAS',
	'CTR' = 'CTR',
	'ROAS' = 'ROAS',
	'CLICKS' = 'clicks',
}

export enum EAdsTypeOptions {
	'CAMPAIGN' = 'campaign',
	'AD_GROUP' = 'adGroup',
	'AD' = 'ad',
}

export enum ERoasAllogatorTypes {
	'CAMPAIGN_BUDGET' = 'campaignBudget',
	'BID_MODIFIER' = 'bid_modifier',
}

export enum EBidModifierDeviceTypes {
	'mobile' = 2,
	'tablet' = 3,
	'desktop' = 4,
}

export enum EKeywordSource {
	gsc = 0,
	ads = 1,
	dataforseo = 2,
}

export enum EKeywordRole {
	primary = 1,
	secondary = 2,
}

export enum ResizeType {
	TOP_LEFT = 'top_left',
	TOP_RIGHT = 'top_right',
	BOTTOM_LEFT = 'bottom_left',
	BOTTOM_RIGHT = 'bottom_right',
	MIDDLE_LEFT = 'middle_left',
	MIDDLE_RIGHT = 'middle_right',
	CENTER_TOP = 'center_top',
	CENTER_BOTTOM = 'center_bottom',
}

export enum EAiArticleType {
	productArticle = 'productArticle',
	productDescription = 'productDescription',
	categoryDescription = 'categoryDescription',
}

export const TO_RADIANS = Math.PI / 180

export type GoogleAdsVisualisationPayload = {
	fromDate: string
	toDate: string
	campaignName?: string
	adGroupResourceName?: string
	all?: boolean
	segment?: 'segments.week' | 'segments.date'
}

export type AdsGoogleCampaignItem = {
	ROAS: number
	averageCPC: number
	campaignBudget: number
	campaignName: string
	conversions: number
	pricePerConversion: number
	costs: number
	conversionValue: number
	color?: string
	size?: GoogleAdsFilterOptions['size']
	type?: EAdsTypeOptions.CAMPAIGN
	onClick?: any
	onEditClick: any
	showDetails?: boolean
	setShowDetails?: any
	campaignResourceName?: string
	campaignBudgetResourceName: string
	campaignStatus: number
	biddingStrategy: string
	adGroupStatus: string
	adGroupName: string
	biddingStrategyType: number
	allogatorSuggestion?: number | null
	toBeCalculatedForROASAllogator?: boolean
	selectedForUpdate?: boolean
}

export enum EFacebookAdsOptions {
	AD = 'ad',
	AD_SETS = 'adSets',
	CAMPAIGN = 'campaign',
}

export type AdGroupBidModifier = {
	ad_group: string
	bid_modifier: number | null
	bid_modifier_source: number
	device: { type: number }
	resource_name: string
	allogatorSuggestion?: number
}
export type GoogleAdsGroupItem = {
	adGroupName: string
	campaignName: string
	averageCPC: number
	ROAS: number
	conversions: number
	clicks: number
	pricePerConversion: number | null
	keyword: GoogleAdKeywordItem[] | null
	color?: string
	size?: GoogleAdsFilterOptions['size']
	type?: EAdsTypeOptions.AD_GROUP
	onClick?: any
	onEditClick?: any
	showDetails?: boolean
	setShowDetails?: any
	campaignResourceName?: string
	adGroupAdResourceName: string
	adGroupResourceName: string
	adGroupStatus: string
	allogatorSuggestion?: number
	ad_group_bid_modifier?: AdGroupBidModifier[]
	toBeCalculatedForROASAllogator?: boolean
	selectedForUpdate?: boolean
}

export type GoogleAdKeywordItem = {
	keyword: string
	clicks: number
	impressions: number
	CTR: number
	averageCPC: number | null
	pricePerConversion: number | null
	conversions: number
	adGroupName: string
	negative: boolean
	color?: string
	size?: GoogleAdsFilterOptions['size']
	changed?: boolean
	matchType: number
	keywordResourceName: string
}

export type SearchAdType = {
	text: string
	asset_performance_label: number
	pinned_field: number
	policy_summary_info: {
		policy_topic_entries: []
		review_status: number
		approval_status: number
	}
}

export type GoogleAdItem = {
	description: string[] | SearchAdType[]
	headlines: string[] | SearchAdType[]
	finalUrl: string | null
	clicks: number
	averageCPC: number
	ROAS: number
	conversions: number
	pricePerConversion: number
	adGroupName: string
	color?: string
	size?: GoogleAdsFilterOptions['size']
	type?: EAdsTypeOptions.AD
	onClick?: any
	onEditClick?: any
	showDetails?: boolean
	setShowDetails?: any
	campaignName?: string
	campaignResourceName?: string
	adGroupAdResourceName: string
	adGroupResourceName: string
	adGroupStatus: string
	path1: string
	path2: string
	adGroupAdType: string
	approving?: boolean
}

export type FacebookAdsActionValues = {
	action_type: string
	value: string
}

export type FacebookAdsItem = {
	color?: string
	size?: FacebookAdsFilterOptions['size']
	type?: EFacebookAdsOptions
	campaignId: string | null
	adSetId: string | null
	adId: string | null
	CPC: string | number | null
	CPM: string | number | null
	bidStrategy: string | number | null
	clicks: string | number | null
	ROAS: string | number | null
	conversions?: number | string
	conversionValue?: number | string
	conversionRate?: number | string
	costPerConversion: string | number | null
	dailyBudget: string | number | null
	dateStart: string | number | null
	dateStop: string | number | null
	frequency: string | number | null
	spend: string | number | null
	reach: string | number | null
	impressions: string | number | null
	actionValues: FacebookAdsActionValues[] | null
	actions: FacebookAdsActionValues[] | null
	name: string
	objective: string | number | null
	status: string
	onClick?: any
	onEditClick?: any
	adCreative?: any
	isPhantomCampaign?: boolean
}

export type FacebookAdEditingPayload = {
	id: string
	name: string
	dailyBudget: string
}

export type FaceBookAdCreativePayload = {
	id: string
	fromDate: string
	toDate: string
}

export type FaceBookAdCreativeEditingPayload = {
	adId: string
	asset_feed_spec: any
	object_story_spec: any
}

export type GoogleAdsBudgetDto = {
	resource_name: string
	amount_micros: number
	bid_modifier: number
}

export type ROASAllogatorUpdatePayload = GoogleAdsBudgetDto[]

export type ROASQuery = {
	validate: boolean
}
export type AuditRowOptions =
	| 'duplicateTitle'
	| 'duplicateMetaDescription'
	| 'brokenLinks'
	| 'missingMetaDescription'
	| 'missingTitle'
	| 'titleTooLong'
	| 'noMainHeading'
	| 'multipleMainHeadings'
	| 'noALTtextOnImage'
	| 'redirectLoop'
	| 'redirect'
	| 'longLoadingTime'
	| 'hasLinksToRedirect'
	| 'noInternalLinks'
	| 'httpsToHttp'

export type AuditMainTags = {
	h1: string[]
	h2: string[]
	h3: string[]
}

export enum ETableOptions {
	'AUDIT',
	'RANKING',
	'PLANNER',
	'PAGE_EXPLORER',
	'PRICE_SCALPING',
	'PRODUCT_CATEGORY',
	'DETAILS_DATA',
}

export enum EGenerateSuggestionStatuses {
	'FAIL' = 0,
	'GENERATING' = 1,
	'GENERATED' = 2,
}

export interface IProject {
	id: number
	name: string | null
	emailSubscribers: string | null
	phoneSubscribers: string | null
	timePerWeek: number | null
	isActivated?: boolean | null
	languageName: string
	locationCode: number
	projectUrl: string | null
	isEcommerce: boolean | null
	conversionValue: number | null
	conversionRate: number | null
	dataSources: IDataSource[] | []
	generateSuggestionStatus: IGenerateSuggestionStatus[] | []
	templateId: string | null
	shopifyProductFeedInterval: number
	customProductLabels?: CustomProductLabel
	company?: Pick<Company, 'id' | 'name' | 'subscriptionLevel'>
}

export interface IExternalLinksForSuggestion {
	rank_group: number
	domain: string
	url: string
	title: string
	breadcrumb: string
}

export interface IDataSource {
	id: number
	status: number
	googleAnalyticsViewId: string | null
	googleAnalyticsSiteUrl: string | null
	googleAdsTokenExpiredTime: string | null
	googleAdsCustomerId: string | null
	googleAdsManagerId: string | null
	googleSearchTokenExpiredTime: string | null
	googleSearchSiteUrl: string | null
	facebookPageId: string | null
	facebookAdManagerId: string | null
	googleAnalyticsAccountId: string | null
	googleAnalyticsWebPropertyId: string | null
	ga4StartDate: string | null
	linkedinToken: string | null
	linkedinPageId: string | null
}
export interface IGenerateSuggestionStatus {
	id: number
	type: string
	status: number
	generatedDate: string
	projectId: number
	updatedAt: string
}

export type AuditSummaryResponse = {
	auditScore: number | null
	createdAt: string | null
	id: number | null
	requestId: string | null
}

export type PageMetrics = {
	[key: string]: string | number
} & { checks: Record<string, string | number> }

export type AuditPageDetailObject = {
	url: string
	title: string
	meta: [] | Record<string, unknown> | null
} & { [key: string]: Record<string, number | string | boolean | never> }

export type AuditSiteDetail = {
	crawl_progress: string | null
	crawl_status: {
		max_crawl_pages: number | null
		pages_in_queue: number | null
		pages_crawled: number | null
	}
	crawl_stop_reason: string | null
	domain_info: {
		name: string | null
		cms: string | null
		ip: string | null
		server: string | null
		crawl_start: string | null
		crawl_end: string | null
		extended_crawl_status: string | null
		ssl_info: {
			valid_certificate: boolean | null
			certificate_issuer: string | null
			certificate_subject: string | null
			certificate_version: number | null
			certificate_hash: string | null
			certificate_expiration_date: string | null
		}
		checks: {
			sitemap: boolean | null
			robots_txt: boolean | null
			start_page_deny_flag: boolean | null
			ssl: boolean | null
			test_canonicalization: boolean | null
			test_hidden_server_signature: boolean | null
			test_page_not_found: boolean | null
			test_directory_browsing: boolean | null
			test_https_redirect: boolean | null
			http2: boolean | null
		}
		total_pages: number | null
		page_not_found_status_code: number | null
		canonicalization_status_code: number | null
		directory_browsing_status_code: number | null
		main_domain: string | null
	}
	page_metrics: {
		links_external: number | null
		links_internal: number | null
		duplicate_title: number | null
		duplicate_description: number | null
		duplicate_content: number | null
		broken_links: number | null
		broken_resources: number | null
		links_relation_conflict: number | null
		redirect_loop: number | null
		onpage_score: number | null
		checks: {
			canonical: number | null
			duplicate_meta_tags: number | null
			no_description: number | null
			frame: number | null
			large_page_size: number | null
			irrelevant_description: number | null
			irrelevant_meta_keywords: number | null
			is_https: number | null
			is_http: number | null
			title_too_long: number | null
			low_content_rate: number | null
			small_page_size: number | null
			no_h1_tag: number | null
			recursive_canonical: number | null
			no_favicon: number | null
			no_image_alt: number | null
			no_image_title: number | null
			seo_friendly_url: number | null
			seo_friendly_url_characters_check: number | null
			seo_friendly_url_dynamic_check: number | null
			seo_friendly_url_keywords_check: number | null
			seo_friendly_url_relative_length_check: number | null
			title_too_short: number | null
			no_content_encoding: number | null
			high_waiting_time: number | null
			high_loading_time: number | null
			is_redirect: number | null
			is_broken: number | null
			is_4xx_code: number | null
			is_5xx_code: number | null
			is_www: number | null
			no_doctype: number | null
			no_encoding_meta_tag: number | null
			high_content_rate: number | null
			low_character_count: number | null
			high_character_count: number | null
			low_readability_rate: number | null
			irrelevant_title: number | null
			deprecated_html_tags: number | null
			duplicate_title_tag: number | null
			no_title: number | null
			flash: number | null
			lorem_ipsum: number | null
			has_misspelling: null
			canonical_to_broken: number | null
			canonical_to_redirect: number | null
			has_links_to_redirects: number | null
			is_orphan_page: number | null
			has_meta_refresh_redirect: number | null
			meta_charset_consistency: number | null
			size_greater_than_3mb: number | null
			has_html_doctype: number | null
			https_to_http_links: number | null
			has_render_blocking_resources: number | null
			redirect_chain: number | null
			canonical_chain: number | null
			is_link_relation_conflict: number | null
		}
	}
}

export type AuditPageDetails = {
	customJsResponse?: AuditCustomJSResponse[]
	duplicateTitle: AuditPageDetailObject[]
	duplicateMetaTags: AuditPageDetailObject[]
	brokenLinks: AuditPageDetailObject[]
	noTitle: AuditPageDetailObject[]
	noDescription: AuditPageDetailObject[]
	titleTooLong: AuditPageDetailObject[]
	noh1Tag: AuditPageDetailObject[]
	multipleh1Tag: AuditPageDetailObject[]
	noImageAlt: AuditPageDetailObject[]
	highLoadingTime: AuditPageDetailObject[]
	hasLinksToRedirects: AuditPageDetailObject[]
	orphanPage: AuditPageDetailObject[]
	httpsToHttpLinks: AuditPageDetailObject[]
	redirectChains?: AuditPageDetailObject[]
}

export type AuditRowOptionsKeys = keyof AuditPageDetails
export interface AuditRowObject {
	detailType: EDetailType
	detailText: string
	url: string
	meta: [] | Record<string, unknown> | null
}

export interface AuditCollection {
	rows: AuditRowObject[]
	value: AuditRowOptionsKeys
	type: ETableOptions.AUDIT
}

export interface AuditCustomJSResponse {
	haveFacebookPixel: boolean
	haveGoogleAnalytics: boolean
	haveTagManager: boolean
}

export interface KeywordRankingObj {
	keyword: string
	ranking: RankingObj[]
	type: ETableOptions.RANKING
}

export interface PriceScalpingObj {
	id: number
	domain: string
	productName: string
	requestIdOfGetProductId: string
	requestIdOfGetPriceList: string
	status: number
	priceList: any[]
	createdAt: Date
	timeInterval: number
	dateToRetrack: string | null
	type?: ETableOptions.PRICE_SCALPING
}

export interface CompetitorAnalysisRow {
	domainCount: string
	domain: string
	top1: string
	top1Keywords: string[]
	top2: string
	top2Keywords: string[]
	top3: string
	top3Keywords: string[]
	top4: string
	top4Keywords: string[]
	isOwn: boolean
}
export interface RankedKeywordObj {
	keyword: string
	CTR: number
	clicks: number
	source: number
	searchVolume: number
	cpc: number
	url: string
	keywordDifficulty: number
	currentPosition: number
	type: ETableOptions.PAGE_EXPLORER
}
export interface CountryObjSelectValue {
	value: number
	label: string
	code?: string
	availableLanguages: LanguageObj[]
}
export interface RankingObj {
	id: number
	position: number
	date: string
	searchVolume: number
	change?: number
	keywordPageUrl: string
	origin?: 'crono' | 'customer'
	clicks: number
	CTR: number
}

export interface LanguageObj {
	language_code: number
	language_name: string
	label?: string
}

export interface LocationObj {
	location_code: string
	location_name: number
}

export interface CountryObj {
	location_code: number
	location_name: string
	country_iso_code?: string
	value: number
	available_languages: LanguageObj[]
}
export interface LanguageObjSelectValue {
	label: string
	value: number
	available_languages?: LanguageObj[]
}

export interface SingleSuggestionResponse {
	keywordData: {
		keyword: string
		search_volume: number
	} | null
	suggestion: {
		keywordValue: {
			name: string
			CTR: number
			conversionRate: number
			role: number
		}
		value: number
		landingPages: {
			url: string
			position: number
			source: number
		}[]
		searchResultUrl: string
		keywordPerformances: {
			name: string
			url: string
			source: number
			position: number
			metadata: {
				wordsCount: number
				totalKeywordCount: number
				titleKeywordCount: number
				metadataDescriptionKeywordCount: number
				imageTitleCount: number
				imageAltCount: number
				headingCount: number
				titleLength: number
				metaDescriptionLength: number
				h1Keyword: boolean
				h2Keyword: boolean
				headingKeywordCount: number
			}
			searchResultUrl: string
		}[]
	} | null
	description: string[]
}
export interface ISuggestedKeywordData {
	id?: number
	type: ETableOptions.PLANNER
	keyword: string
	location_code: number
	language_code: string
	bingMonthlySearchVolume?: number | null
	monthlySearchVolume?: number | null
	cpc?: number | null
	keyword_info: {
		last_updated_time: string
		competition: number
		cpc: number
		search_volume: number
		categories: number[]
		monthly_searches: {
			year: number
			month: number
			search_volume: number
		}[]
	}
	impressions_info: {
		last_updated_time: string
		bid: number
		match_type: string
		ad_position_min: number
		ad_position_max: number
		ad_position_average: number
		cpc_min: number
		cpc_max: number
		cpc_average: number
		daily_impressions_min: number
		daily_impressions_max: number
		daily_impressions_average: number
		daily_clicks_min: number
		daily_clicks_max: number
		daily_clicks_average: number
		daily_cost_min: number
		daily_cost_max: number
		daily_cost_average: number
	}
	bing_keyword_info: {
		last_updated_time: string
		search_volume: number
		monthly_searches: {
			year: number
			month: number
			search_volume: number
		}[]
	}
	serp_info: string | null
	relatedKeywordData: {
		depth: number
		keyword_data: ISuggestedKeywordData
		related_keywords: string[]
	}[]
}
export const createAuditDetailRow = (
	row: AuditPageDetailObject,
): AuditRowObject => {
	return {
		...row,
		url: row.url,
		detailText: 'testing',
		detailType: EDetailType.WARNING,
	}
}

export interface generalCategoryObj {
	id: number
	name: string
	slug: string
	description: string
	count: number
	url: string
	products: IProductWithConversion[]
	subCategory?: generalCategoryObj[]
	searchConsoleKeywords: ISearchConsoleKeyword[]
}

export interface categoryMapObj {
	id: number
	name: string
	slug: string
	description: string
	count: number
	url: string
	products: IProductWithConversion[]
	subCategory: generalCategoryObj[]
	searchConsoleKeywords: ISearchConsoleKeyword[]
	type?: ETableOptions.PRODUCT_CATEGORY
}

export interface IProduct {
	id: number
	name: string
	slug: string
	type: string
	sku: string
	description?: string
	on_sale: boolean
	prices: IPrice
	average_rating: string
	images?: IImageData[]
	categories: ICategory[]
	is_purchasable: boolean
	is_in_stock: boolean
	low_stock_remaining?: number
	add_to_cart: IAddToCart
	url: string
}

export interface IProductWithConversion extends IProduct {
	searchConsoleKeywords: ISearchConsoleKeyword[]
	clicks: number
	conversionsValue: number
	costMicros: number
	conversions: number
	allConversions: number
}

export interface IPrice {
	price: string
	regular_price: string
	sale_price: string
	currency_code: string
	currency_symbol: string
	currency_minor_unit: number
	currency_decimal_separator: string
	currency_suffix: string
}

export interface IAddToCart {
	url: string
	minimum: number
	maximum: number
	multiple_of: number
}

export interface IImageData {
	id: number
	src: string
	thumbnail: string
	sizes: string
	name: string
}

export interface ICategory {
	id: number
	name: string
	slug: string
	link: string
}

export interface ISearchConsoleKeyword {
	keyword: string
	CTR: number
	clicks: number
	impression: number
	position: number
	searchVolume?: number
	type?: ETableOptions.DETAILS_DATA
}

export enum ETypeOfItem {
	product = 'product',
	category = 'category',
}

const transformSiteErrorKey = (
	key: string,
): keyof Omit<AuditPageDetails, 'customJsResponse'> => {
	switch (key) {
		case 'duplicateTitle':
		case 'duplicateMetaTags':
		case 'duplicateMetaDescription':
		case 'redirectChains':
		case 'brokenLinks':
		case 'noTitle':
		case 'noDescription':
		case 'titleTooLong':
		case 'noh1Tag':
		case 'multipleh1Tag':
		case 'noImageAlt':
		case 'highLoadingTime':
		case 'hasLinksToRedirects':
		case 'orphanPage':
		case 'httpsToHttpLinks':
			return i18n.t(`audit.${key}`)
		default:
			return key as keyof Omit<AuditPageDetails, 'customJsResponse'>
	}
}

export const getOptions = <T extends object>(
	optionsObject: T,
): AuditCollection[] => {
	const options: AuditCollection[] = []
	for (const [key, value] of Object.entries(optionsObject)) {
		if (key !== 'customJsResponse') {
			const rows = value.map(createAuditDetailRow)
			const transFormedKey = transformSiteErrorKey(key)
			options.push({
				value: transFormedKey as keyof AuditPageDetails,
				rows,
				type: ETableOptions.AUDIT,
			})
		}
	}
	return options
}

type AdsSaveCampaignObject = {
	campaignName: string
}

export type AdsSaveObject =
	| ({
			type: EAdsTypeOptions
	  } & AdsSaveCampaignObject)
	| GoogleAdItemType

export const transformAdsObject = (
	adsObject: AdsSaveObject,
): Record<string, any> => {
	const obj: any = {}
	switch (adsObject.type) {
		case EAdsTypeOptions.CAMPAIGN:
			obj.name = adsObject.campaignName
			break

		default:
			break
	}
	return obj
}

function tranformCountryToOption(option: CountryObj): CountryObjSelectValue {
	return {
		value: option.location_code,
		label: option.location_name,
		code: option.country_iso_code,
		availableLanguages: option.available_languages,
	}
}

function tranformLanguageToOption(option: LanguageObj): LanguageObjSelectValue {
	return { value: option.language_code, label: option.language_name }
}

export const transformCountries = (
	countryArray: CountryObj[],
): CountryObjSelectValue[] => {
	if (!Array.isArray(countryArray)) {
		return []
	}
	return countryArray.map(tranformCountryToOption)
}
export const transformLanguages = (
	languageArray: LanguageObj[],
): LanguageObjSelectValue[] => {
	if (!Array.isArray(languageArray)) {
		return []
	}
	return languageArray.map(tranformLanguageToOption)
}

export const findlocationCode = (
	countryArray: CountryObjSelectValue[] = [],
) => {
	return function (
		selectedCountry: number,
	): CountryObjSelectValue | Record<string, never> {
		const found = countryArray.find(
			country => country.value === selectedCountry,
		)
		return found || {}
	}
}

export const findLanguageName = (
	languageArray: LanguageObjSelectValue[] = [],
) => {
	return function (
		selectedLanguage: string,
	): LanguageObjSelectValue | Record<string, never> {
		const found = languageArray.find(
			language => language.label === selectedLanguage,
		)

		return found || {}
	}
}

export const addKeywordPositionChange = (arr: RankingObj[]): RankingObj[] => {
	const array = [...arr]
	for (let index = 0; index < array.length; index++) {
		if (index + 1 !== array.length) {
			array[index].change = array[index].position - array[index + 1].position
		} else {
			array[index].change = undefined
		}
	}
	return array
}

export const sortByDate = (
	arr: RankingObj[],
	field: keyof RankingObj,
): RankingObj[] => {
	if (!arr) {
		return arr
	}
	return arr.sort((a, b) => {
		return moment(b[field]).unix() - moment(a[field]).unix()
	})
}

export const sortKeywordRankingByDate = (
	arr: keywordRankingWithLabel[],
): keywordRankingWithLabel[] => {
	const array = [...arr]
	for (const item of array) {
		let sorted = sortByDate(item.ranking, 'date')
		sorted = addKeywordPositionChange(sorted)
		item.type = ETableOptions.RANKING
		item.ranking = sorted
	}
	return array
}

export const calculateAdsColorValue = (
	valueToCompare: number,
	operator: EOperators,
	targetValue: number | string,
): string => {
	if (targetValue === '' || isNaN(Number(targetValue))) {
		return 'neutral'
	}

	const numericTargetValue = Number(targetValue)

	if (valueToCompare === 0 && operator !== EOperators['=']) {
		return 'neutral'
	}

	switch (operator) {
		case EOperators['<']:
			return valueToCompare < numericTargetValue ? 'green' : 'red'
		case EOperators['<=']:
			return valueToCompare <= numericTargetValue ? 'green' : 'red'
		case EOperators['=']:
			return valueToCompare === numericTargetValue ? 'green' : 'red'
		case EOperators['>']:
			return valueToCompare > numericTargetValue ? 'green' : 'red'
		case EOperators['>=']:
			return valueToCompare >= numericTargetValue ? 'green' : 'red'
		default:
			return 'neutral'
	}
}

export const sortByGreenColor = <T extends { color?: string }>(
	a: T,
	b: T,
): number => {
	if (a.color === 'green' && b.color !== 'green') return -1
	if (b.color === 'green' && a.color !== 'green') return 1
	return 0
}

export enum ECampaignStatus {
	ENABLED = 2,
	PAUSED = 3,
	REMOVED = 4,
}
export enum EAdGroupStatus {
	ENABLED = 2,
	PAUSED = 3,
	REMOVED = 4,
}

export enum EKeywordMatchType {
	EXACT = 2,
	PHRASE = 3,
	BROAD = 4,
}

export enum EPinnedHeadline {
	HEADLINE_1 = 2,
	HEADLINE_2 = 3,
	HEADLINE_3 = 4,
}

export enum EpinnedDescription {
	DESCRIPTION_1 = 5,
	DESCIPRTION_2 = 6,
}

export enum EBiddingStrategyTypes {
	ENHANCED_CPC = 2,
	MANUAL_CPC = 3,
	MANUAL_CPM = 4,
	PAGE_ONE_PROMOTED = 5,
	TARGET_CPA = 6,
	TARGET_OUTRANK_SHARE = 7,
	TARGET_ROAS = 8,
	TARGET_SPEND = 9,
	MAXIMIZE_CONVERSIONS = 10,
	MAXIMIZE_CONVERSION_VALUE = 11,
	PERCENT_CPC = 12,
	MANUAL_CPV = 13,
	TARGETS = 14,
	TARGET_IMPRESSION_SHARE = 15,
	COMMISSION = 16,
}

export enum EAdGroupAdType {
	UNSPECIFIED = 0,
	UNKNOWN = 1,
	TEXT_AD = 2,
	EXPANDED_TEXT_AD = 3,
	CALL_ONLY_AD = 6,
	EXPANDED_DYNAMIC_SEARCH_AD = 7,
	HOTEL_AD = 8,
	SHOPPING_SMART_AD = 9,
	SHOPPING_PRODUCT_AD = 10,
	VIDEO_AD = 12,
	GMAIL_AD = 13,
	IMAGE_AD = 14,
	RESPONSIVE_SEARCH_AD = 15,
	LEGACY_RESPONSIVE_DISPLAY_AD = 16,
	APP_AD = 17,
	LEGACY_APP_INSTALL_AD = 18,
	RESPONSIVE_DISPLAY_AD = 19,
	LOCAL_AD = 20,
	HTML5_UPLOAD_AD = 21,
	DYNAMIC_HTML5_AD = 22,
	APP_ENGAGEMENT_AD = 23,
	SHOPPING_COMPARISON_LISTING_AD = 24,
	VIDEO_BUMPER_AD = 25,
	VIDEO_NON_SKIPPABLE_IN_STREAM_AD = 26,
	VIDEO_OUTSTREAM_AD = 27,
	VIDEO_TRUEVIEW_DISCOVERY_AD = 28,
	VIDEO_TRUEVIEW_IN_STREAM_AD = 29,
	VIDEO_RESPONSIVE_AD = 30,
}

export enum EClientType {
	//No value has been specified.
	'UNSPECIFIED',
	// Used for return value only. Represents an unclassified client type unknown in this version.
	'UNKNOWN',
	// Changes made through the "ads.google.com". For example, changes made through campaign management.
	'GOOGLE_ADS_WEB_CLIENT',
	// Changes made through Google Ads automated rules.
	'GOOGLE_ADS_AUTOMATED_RULE',
	// Changes made through Google Ads scripts.
	'GOOGLE_ADS_SCRIPTS',
	// Changes made by Google Ads bulk upload.
	'GOOGLE_ADS_BULK_UPLOAD',
	// Changes made by Google Ads API.
	'GOOGLE_ADS_API',
	// Changes made by Google Ads Editor. This value is a placeholder. The API does not return these changes.
	'GOOGLE_ADS_EDITOR',
	// Changes made by Google Ads mobile app.
	'GOOGLE_ADS_MOBILE_APP',
	// Changes made through Google Ads recommendations.
	'GOOGLE_ADS_RECOMMENDATIONS',
	// Changes made through Search Ads 360 Sync.
	'SEARCH_ADS_360_SYNC',
	//Changes made through Search Ads 360 Post.
	'SEARCH_ADS_360_POST',
	//Changes made through internal tools. For example, when a user sets a URL template on an entity like a Campaign, it's automatically wrapped with the SA360 Clickserver URL.
	'INTERNAL_TOOL',
	// Types of changes that are not categorized, for example, changes made by coupon redemption through Google Ads.
	'OTHER',
}

export enum EChangeEventResourceType {
	// No value has been specified.
	'UNSPECIFIED',
	// Used for return value only. Represents an unclassified resource unknown in this version.
	'UNKNOWN',
	// An Ad resource change.
	'AD',
	// An AdGroup resource change.
	'AD_GROUP',
	// An AdGroupCriterion resource change.
	'AD_GROUP_CRITERION',
	// A Campaign resource change.
	'CAMPAIGN',
	// A CampaignBudget resource change.
	'CAMPAIGN_BUDGET',
	// An AdGroupBidModifier resource change.
	'AD_GROUP_BID_MODIFIER',
	// A CampaignCriterion resource change.
	'CAMPAIGN_CRITERION',
	// A Feed resource change.
	'FEED',
	// A FeedItem resource change.
	'FEED_ITEM',
	// A CampaignFeed resource change.
	'CAMPAIGN_FEED',
	// An AdGroupFeed resource change.
	'AD_GROUP_FEED',
	// An AdGroupAd resource change.
	'AD_GROUP_AD',
	// An Asset resource change.
	'ASSET',
	// A CustomerAsset resource change.
	'CUSTOMER_ASSET',
	// A CampaignAsset resource change.
	'CAMPAIGN_ASSET',
	// An AdGroupAsset resource change.
	'AD_GROUP_ASSET',
	// An AssetSet resource change.
	'ASSET_SET',
	// An AssetSetAsset resource change.
	'ASSET_SET_ASSET',
	// A CampaignAssetSet resource change.
	'CAMPAIGN_ASSET_SET',
}

enum EResourceChangeOperation {
	// No value has been specified.
	'UNSPECIFIED',
	// Used for return value only. Represents an unclassified operation unknown in this version.
	'UNKNOWN',
	// The resource was created.
	'CREATE',
	// The resource was modified.
	'UPDATE',
	// The resource was removed.
	'REMOVE',
}

enum EAdsStatus {
	// No value has been specified.
	'UNSPECIFIED' = 'UNSPECIFIED',
	// The received value is not known in this version.
	// This is a response-only value.
	'UNKNOWN' = 'UNKNOWN',
	// The ad group criterion is enabled.
	'ENABLED' = 'ENABLED',
	// The ad group criterion is paused.
	'PAUSED' = 'PAUSED',
	// The ad group criterion is removed.
	'REMOVED' = 'REMOVED',
}

enum EAdsKeywordMatchType {
	// Not specified.
	'UNSPECIFIED' = 'UNSPECIFIED',
	// Used for return value only. Represents value unknown in this version.
	'UNKNOWN' = 'UNKNOWN',
	// Exact match.
	'EXACT' = 'EXACT',
	// Phrase match.
	'PHRASE' = 'PHRASE',
	// Broad match.
	'BROAD' = 'BROAD',
}

// type GoogleChangeEventAdGroup = {
// 	ad_group: {
// 		resource_name: string
// 		status: EAdsStatus
// 		type: string
// 		ad_rotation_mode: string
// 		display_custom_bid_dimension: string
// 		effective_target_cpa_source: string
// 		effective_target_roas_source: string
// 	}
// }
// type GoogleChangeEventAdGroupAd = {
// 	ad_group_ad: {
// 		resource_name: string
// 		status: EAdGroupStatus
// 		ad: {
// 			type: string
// 			device_preference: string
// 			system_managed_resource_source: string
// 			resource_name: string
// 			ad_strength: string
// 		}
// 	}
// }
// type GoogleChangeEventAssets = {
// 	assets: {
// 		resource_name: string
// 		type: string
// 		id: string
// 		source: string
// 		text_asset?: {
// 			text: string
// 		}
// 	}
// }
// type GoogleChangeEventAdCriterion = {
// 	ad_group_criterion: {
// 		resource_name: string
// 		status: EAdsStatus
// 		effective_cpc_bid_source: string
// 		effective_cpm_bid_source: string
// 		effective_cpv_bid_source: string
// 		type: string
// 		keyword: {
// 			match_type: EAdsKeywordMatchType
// 			text: string
// 		}
// 		effective_percent_cpc_bid_source: string
// 		system_serving_status: string
// 		approval_status: string
// 		criterion_id: string
// 		ad_group: string
// 		negative: boolean
// 		display_name: string
// 	}
// }

// export type GoogleAdsChangeEvent = {
// 	resource_name: string
// 	change_date_time: string
// 	change_resource_name: string
// 	user_email: string
// 	client_type: EClientType
// 	change_resource_type: EChangeEventResourceType
// 	old_resource:
// 		| GoogleChangeEventAdGroup
// 		| GoogleChangeEventAdCriterion
// 		| GoogleChangeEventAdGroupAd
// 		| GoogleChangeEventAssets
// 	new_resource:
// 		| GoogleChangeEventAdGroup
// 		| GoogleChangeEventAdCriterion
// 		| GoogleChangeEventAdGroupAd
// 		| GoogleChangeEventAssets
// 	resource_change_operation: EResourceChangeOperation
// 	changed_fields: {
// 		paths: string[]
// 	}
// }

export type GoogleAdsChangeEvent = {
	resource_name: string
	change_date_time: string
	change_resource_name: string
	user_email: UserEmail
	client_type: number
	change_resource_type: number
	old_resource: OldResource
	new_resource: NewResource
	resource_change_operation: number
	changed_fields: ChangedFields
	campaign: string
	ad_group: string
}

export type ChangedFields = {
	paths: string[]
}

export type NewResource = {
	ad_group_criterion?: NewResourceAdGroupCriterion
	ad_group?: AdGroup
	ad_group_ad?: AdGroupAd
	ad?: NewResourceAd
	campaign_asset?: NewResourceCampaignAsset
	ad_group_bid_modifier?: NewResourceAdGroupBidModifier
	asset?: NewResourceAsset
	campaign?: NewResourceCampaign
	campaign_criterion?: NewResourceCampaignCriterion
	campaign_budget?: NewResourceCampaignBudget
}

export type NewResourceAd = {
	type: DevicePreference
	device_preference: DevicePreference
	responsive_search_ad: PurpleResponsiveSearchAd
	system_managed_resource_source: DevicePreference
	resource_name: string
	final_urls?: string[]
	tracking_url_template?: string
	final_url_suffix?: string
	added_by_google_ads?: boolean
}

export type DevicePreference = 'UNSPECIFIED' | 'PAUSED'

export type PurpleResponsiveSearchAd = {
	headlines: Description[]
	descriptions?: Description[]
	path1?: string
	path2?: string
}

export type Description = {
	pinned_field: DevicePreference
	text: string
	asset_performance_label: DevicePreference
}

export type AdGroup = {
	resource_name: string
	status: string
	type: DevicePreference
	ad_rotation_mode: DevicePreference
	display_custom_bid_dimension: DevicePreference
	effective_target_cpa_source: DevicePreference
	effective_target_roas_source: DevicePreference
}

export type AdGroupAd = {
	resource_name: string
	status: Status
	ad: AdGroupAdAd
	ad_strength: DevicePreference
}

export type AdGroupAdAd = {
	type: DevicePreference
	device_preference: DevicePreference
	system_managed_resource_source: DevicePreference
	resource_name: string
}

export type Status = 'ENABLED' | 'PAUSED'

export type NewResourceAdGroupBidModifier = {
	resource_name: string
	bid_modifier_source: DevicePreference
	device: Device
	ad_group: string
	criterion_id: string
	bid_modifier: number
}

export type Device = {
	type: string
}

export type NewResourceAdGroupCriterion = {
	resource_name: string
	status: DevicePreference
	effective_cpc_bid_source: DevicePreference
	effective_cpm_bid_source: DevicePreference
	effective_cpv_bid_source: DevicePreference
	type: DevicePreference
	effective_percent_cpc_bid_source: DevicePreference
	system_serving_status: DevicePreference
	approval_status: DevicePreference
	display_name: string
}

export type NewResourceAsset = {
	resource_name: string
	type: DevicePreference
	text_asset?: TextAsset
	id: string
	source: DevicePreference
	name?: string
	callout_asset?: CalloutAsset
	youtube_video_asset?: YoutubeVideoAsset
	final_urls?: string[]
	sitelink_asset?: SitelinkAsset
	price_asset?: PriceAsset
}

export type CalloutAsset = {
	callout_text: string
	start_date: string
	end_date: string
}

export type PriceAsset = {
	type: string
	price_qualifier: string
	language_code: string
	price_offerings: PriceOffering[]
}

export type PriceOffering = {
	header: string
	description: string
	price: Price
	unit: string
	final_url: string
	final_mobile_url: string
}

export type Price = {
	currency_code: string
	amount_micros: string
}

export type SitelinkAsset = {
	link_text: string
	description1: string
	description2: string
	start_date: string
	end_date: string
}

export type TextAsset = {
	text: string
}

export type YoutubeVideoAsset = {
	youtube_video_id: string
	youtube_video_title: string
}

export type NewResourceCampaign = {
	resource_name: string
	status: Status
	ad_serving_optimization_status: string
	advertising_channel_type: string
	advertising_channel_sub_type: DevicePreference
	network_settings: NetworkSettings
	experiment_type: DevicePreference
	serving_status: DevicePreference
	bidding_strategy_type: DevicePreference
	video_brand_safety_suitability: DevicePreference
	geo_target_type_setting: GeoTargetTypeSetting
	payment_mode: DevicePreference
	name: string
	id: string
	campaign_budget: string
	accessible_bidding_strategy: string
	url_expansion_opt_out: boolean
	audience_setting: AudienceSetting
}

export type AudienceSetting = {
	use_audience_grouped: boolean
}

export type GeoTargetTypeSetting = {
	positive_geo_target_type: string
	negative_geo_target_type: string
}

export type NetworkSettings = {
	target_google_search: boolean
	target_search_network: boolean
	target_content_network: boolean
	target_partner_search_network: boolean
}

export type NewResourceCampaignAsset = {
	resource_name: string
	field_type: string
	status: Status
	source: DevicePreference
	campaign?: string
	asset?: string
}

export type NewResourceCampaignBudget = {
	resource_name: string
	status: DevicePreference
	delivery_method: string
	period: DevicePreference
	type: string
	id: string
	amount_micros: string
	explicitly_shared: boolean
}

export type NewResourceCampaignCriterion = {
	resource_name: string
	type: DevicePreference
	status: Status
	campaign: string
	criterion_id: string
	negative: boolean
	display_name: string
}

export type OldResource = {
	ad_group_criterion?: OldResourceAdGroupCriterion
	ad_group?: AdGroup
	ad_group_ad?: AdGroupAd
	ad?: OldResourceAd
	campaign_asset?: OldResourceCampaignAsset
	ad_group_bid_modifier?: OldResourceAdGroupBidModifier
	asset?: OldResourceAsset
	campaign?: OldResourceCampaign
	campaign_criterion?: OldResourceCampaignCriterion
	campaign_budget?: OldResourceCampaignBudget
}

export type OldResourceAd = {
	type: DevicePreference
	device_preference: DevicePreference
	responsive_search_ad?: FluffyResponsiveSearchAd
	system_managed_resource_source: DevicePreference
	resource_name: string
	final_urls?: string[]
	added_by_google_ads?: boolean
}

export type FluffyResponsiveSearchAd = {
	headlines: Description[]
	descriptions: Description[]
}

export type OldResourceAdGroupBidModifier = {
	resource_name: string
	bid_modifier_source: DevicePreference
}

export type OldResourceAdGroupCriterion = {
	resource_name: string
	status: Status
	effective_cpc_bid_source: DevicePreference
	effective_cpm_bid_source: DevicePreference
	effective_cpv_bid_source: DevicePreference
	type: DevicePreference
	keyword?: Keyword
	effective_percent_cpc_bid_source: DevicePreference
	system_serving_status: DevicePreference
	approval_status: DevicePreference
	criterion_id?: string
	ad_group?: string
	negative?: boolean
	display_name: string
}

export type Keyword = {
	match_type: string
	text: string
}

export type OldResourceAsset = {
	resource_name: string
	type: DevicePreference
	source: DevicePreference
}

export type OldResourceCampaign = {
	resource_name: string
	status: DevicePreference
	ad_serving_optimization_status: DevicePreference
	advertising_channel_type: DevicePreference
	advertising_channel_sub_type: DevicePreference
	experiment_type: DevicePreference
	serving_status: DevicePreference
	bidding_strategy_type: DevicePreference
	video_brand_safety_suitability: DevicePreference
	payment_mode: DevicePreference
	accessible_bidding_strategy: string
}

export type OldResourceCampaignAsset = {
	resource_name: string
	field_type: DevicePreference
	status: DevicePreference
	source: DevicePreference
}

export type OldResourceCampaignBudget = {
	resource_name: string
	status: DevicePreference
	delivery_method: DevicePreference
	period: DevicePreference
	type: DevicePreference
}

export type OldResourceCampaignCriterion = {
	resource_name: string
	type: DevicePreference
	status: DevicePreference
	display_name: string
}

export type UserEmail = 'Recommendations Auto-Apply' | string

type EnumKeys<Enum> = Exclude<keyof Enum, number>

export const enumToObject = <Enum extends Record<string, number | string>>(
	e: Enum,
): Record<string, unknown> => {
	const copy = { ...e } as { [K in EnumKeys<Enum>]: Enum[K] }
	Object.values(e).forEach(
		value => typeof value === 'number' && delete copy[value],
	)
	return copy
}
export enum ESubscriptionLevel {
	TRIAL = 'TRIAL',
	NOT_SUBSCRIBED = 'NOT_SUBSCRIBED',
	BASIC = 'BASIC',
	PREMIUM20 = 'PREMIUM20',
	PREMIUM50 = 'PREMIUM50',
	ENTERPRISE = 'ENTERPRISE',
	EXPIRED = 'EXPIRED',
}

export enum HTTP_CODE {
	OK = 200,
	CREATED = 201,
	NO_CONTENT = 204,
	BAD_REQUEST = 400,
	UNAUTHENTICATED = 401,
	PAYMENT_REQUIRED = 402,
	FORBIDDEN = 403,
	NOT_FOUND = 404,
	INTERNAL_ERROR = 500,
}

export const canAccessWithSubscriptionLevel = (
	subscriptionLevel: ESubscriptionLevel,
	subSubcriptionLevels: ESubscriptionLevel[],
): boolean => {
	if (!subscriptionLevel || !subSubcriptionLevels) {
		return false
	}
	const isFound = subSubcriptionLevels.find(
		level => level === subscriptionLevel,
	)
	return isFound ? true : false
}

export const isCompanyOnFreeVersion = (
	subscriptionLevel: ESubscriptionLevel,
): boolean => {
	return canAccessWithSubscriptionLevel(subscriptionLevel, [
		ESubscriptionLevel.NOT_SUBSCRIBED,
	])
}

export const isAccessibleToBulkContentCreation = (
	subscriptionLevel: ESubscriptionLevel,
): boolean => {
	return [ESubscriptionLevel.PREMIUM50, ESubscriptionLevel.ENTERPRISE].includes(
		subscriptionLevel,
	)
}

export const copyToClipBoard = (id = 'copy-text'): void => {
	if (document !== null) {
		try {
			const copyText = document.getElementById(id)
			const textArea = document.createElement('textarea')
			textArea.value = copyText?.textContent ?? ''
			document.body.appendChild(textArea)
			textArea.select()
			document.execCommand('Copy')
			textArea.remove()
		} catch (error) {
			console.error(error)
		}
	}
}

export const getChartMaxValueKeyByField = (
	combinedData: Record<string, any>[],
	field: string,
	isLastYearAvailable: boolean,
	lastYearField: string,
	lastMonthField: string,
): string => {
	return _.maxBy(
		combinedData,
		isLastYearAvailable ? lastYearField : lastMonthField,
	)?.[isLastYearAvailable ? lastYearField : lastMonthField] >
		_.maxBy(combinedData, field)?.field
		? isLastYearAvailable
			? lastYearField
			: lastMonthField
		: field
}

export const asyncDelay = async (timeout: number): Promise<void> => {
	return new Promise(resolve => {
		setTimeout(() => {
			return resolve()
		}, timeout)
	})
}

export enum ENewTypeOfPost {
	article = 'article',
	product_description = 'product description',
	product_category_text = 'product category text',
	email = 'email',
	ideas = 'ideas',
	custom = 'custom',
}
export enum ELegacyTypeOfPost {
	article = 'article',
	product_description = 'product description',
	product_category_text = 'product category text',
	email = 'email',
	ideas = 'ideas',
}

export enum ETypeOfPost {
	product = 'product',
	informational = 'informational',
	behindTheScenes = 'behind the scenes',
	competition = 'competition',
	quote = 'quote',
	story = 'story',
	graph = 'graph',
	analytical = 'analytical',
	news = 'news',
	engagement = 'engagement',
	poll = 'poll',
	article = 'article',
	product_description = 'product description',
	product_category_text = 'product category text',
	email = 'email',
	ideas = 'ideas',
	// free_text = 'free text',
	non_specify = 'non_specify',
	// custom = 'custom',
}

export enum ECharacteristicOfThePost {
	long = 'long',
	short = 'short',
	fun = 'fun',
	creative = 'creative',
	hilarious = 'hilarious',
	easy = 'easy',
	serious = 'serious',
	commercial = 'commercial',
	non_commercial = 'non commercial',
	non_specify = 'non_specify',
}

export enum ETypeOfPostFinnish {
	product = 'tuote',
	informational = 'informaatio',
	behindTheScenes = 'kulissien takana',
	competition = 'kilpailu',
	quote = 'lainaus',
	story = 'tarina',
	graph = 'kaavio',
	analytical = 'analyyttinen',
	news = 'uutiset',
	engagement = 'sitoutuminen',
	poll = 'äänestys',
	article = 'artikkeli',
	product_description = 'tuotekuvaus',
	product_category_text = 'tuotekategorian teksti',
	email = 'sähköposti',
	ideas = 'ideat',
	// free_text = 'vapaa teksti',
	non_specify = 'ei määritelty',
	//custom = 'custom',
}

export enum ECharacteristicOfThePostFinnish {
	long = 'pitkä',
	short = 'lyhyt',
	fun = 'hauska',
	creative = 'luova',
	hilarious = 'hämmästyttävä',
	easy = 'helppo',
	serious = 'vakava',
	commercial = 'kaupallinen',
	non_commercial = 'ei-kaupallinen',
	non_specify = 'ei määritelty',
}

export enum EMagicWriteOption {
	change_tone = 'change tone',
	add_more_text = 'add more text',
	compress = 'compress',
	fix_grammar = 'fix grammar',
	add_fairy_dust = 'add fairy dust',
	custom = 'custom',
}

export enum EMagicWriteOptionFinnish {
	change_tone = 'vaihda sävyä',
	add_more_text = 'lisää tekstiä',
	compress = 'pakkaa',
	fix_grammar = 'korjaa kielioppi',
	add_fairy_dust = 'lisää keijupöly',
	custom = 'mukautettu',
}

export enum EDayOfTheWeek {
	monday = 'monday',
	tuesday = 'tuesday',
	wednesday = 'wednesday',
	thursday = 'thursday',
	friday = 'friday',
	saturday = 'saturday',
	sunday = 'sunday',
}

export enum ESomePostType {
	BLANK_POST = 'blank_post',
	FACEBOOK = 'facebook',
	INSTAGRAM = 'instagram',
	INSTAGRAM_SCHEDULED = 'instagram_scheduled',
	LINKEDIN = 'linkedIn',
	LINKEDIN_SCHEDULED = 'linkedin_scheduled',
}

function isPlainEnoughObject(o: unknown) {
	return o !== null && !Array.isArray(o) && typeof o === 'object'
}
/*
  autoMergeLevel3:
    - merges 2 level of substate
    - skips substate if already modified
    - this is essentially redux-perist v4 behavior
*/

export const autoMergeLevel3 = (
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	inboundState: any,
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	originalState: any,
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	reducedState: any,
	{ debug }: PersistConfig,
): any => {
	const newState = _.cloneDeep(reducedState)
	// only rehydrate if inboundState exists and is an object
	if (inboundState && typeof inboundState === 'object') {
		Object.keys(inboundState).forEach(key => {
			// ignore _persist data
			if (key === '_persist') {
				return
			}
			// if reducer modifies substate, skip auto rehydration
			if (originalState[key] !== reducedState[key]) {
				if (process.env.NODE_ENV !== 'production' && debug)
					// eslint-disable-next-line no-console
					console.log(
						'redux-persist/stateReconciler: sub state for key `%s` modified, skipping.',
						key,
					)
				return
			}
			if (isPlainEnoughObject(reducedState[key])) {
				// if object is plain enough shallow merge the new values (hence "Level2")
				newState[key] = _.merge(newState[key], inboundState[key])
				return
			}
			// otherwise hard set
			newState[key] = inboundState[key]
		})
	}

	if (
		process.env.NODE_ENV !== 'production' &&
		debug &&
		inboundState &&
		typeof inboundState === 'object'
	)
		// eslint-disable-next-line no-console
		console.log(
			`redux-persist/stateReconciler: rehydrated keys '${Object.keys(
				inboundState,
			).join(', ')}'`,
		)

	return newState
}

export interface WordPressPageType {
	author: number
	categories: number[]
	content: { protected: boolean; rendered: string }
	date: string
	date_gmt: string
	excerpt: { protected: boolean; rendered: string }
	featured_media: number
	guid: { rendered: string }
	id: number
	link: string
	menu_order: number
	meta: any[]
	modified: string
	modified_gmt: string
	parent: number
	ping_status: string
	slug: string
	status: string
	template: string
	title: { rendered: string }
	type: 'page'
	yoast_head: string
}
export interface WordPressPostType {
	author: number
	comment_status: string
	content: { protected: boolean; rendered: string }
	date: string
	date_gmt: string
	excerpt: { protected: boolean; rendered: string }
	featured_media: number
	guid: { rendered: string }
	id: number
	link: string
	menu_order: number
	meta: any[]
	modified: string
	modified_gmt: string
	parent: number
	ping_status: string
	slug: string
	status: string
	sticky: boolean
	tags: any[]
	template: string
	title: { rendered: string }
	type: 'post'
	yoast_head: string
}

export const quillModules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		// Currently these are not working properly for the text
		// [{ size: ['small', 'large', 'huge'] }],
		// Align uses value from css file, so it will not be transfered when copying html
		// [{ align: [] }],
		// Currently these are not working properly for the text
		// [{ color: [] }, { background: [] }],
		[
			{ list: 'ordered' },
			{ list: 'bullet' },
			{ indent: '-1' },
			{ indent: '+1' },
		],
		['link', 'image'],
		['clean'],
	],
}
export const quillFormats = [
	'header',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'indent',
	'link',
	'image',
]
export const taskPriorityValues = {
	LOW: 1,
	MEDIUM: 2,
	HIGH: 3,
}

export enum ETaskPriority {
	'LOW' = 1,
	'MEDIUM' = 2,
	'HIGH' = 3,
}

export enum ETaskType {
	'CUSTOM' = 'CUSTOM',
	'INFO' = 'INFO',
	'GUIDE' = 'GUIDE',
	'CRITICAL' = 'CRITICAL',
	'ERROR' = 'ERROR',
	'WARNING' = 'WARNING',
}

export enum ETaskCategory {
	SEO = 'SEO',
	CRO = 'CRO',
	ADS = 'ADS',
	SOME = 'SOME',
	TASK = 'TASK',
}

export const categoryMenu = (): any[] => [
	{ label: 'SEO', value: ETaskCategory.SEO },
	{
		label: 'Google Analytics',
		value: ETaskCategory.CRO,
	},
	{
		label: 'Google Ads',
		value: ETaskCategory.ADS,
	},
	{
		label: 'Facebook Ads',
		value: ETaskCategory.SOME,
	},
	{
		label: i18n.t('tasks.filters.task'),
		value: ETaskCategory.TASK,
	},
]

export enum ETaskAttribute {
	SEO = 'SEO',
	ADS_account = 'ADS_account',
	ADS_campaign = 'ADS_campaign',
	ADS_adGroup = 'ADS_adGroup',
	ADS_ad = 'ADS_ad',
	SOME_account = 'SOME_account',
	SOME_campaign = 'SOME_campaign',
	SOME_adSet = 'SOME_adSet',
	SOME_ad = 'SOME_ad',
	CRO_exactPath = 'CRO_exactPath',
	CRO_includePath = 'CRO_includePath',
}

export const customTaskTrackingAttribute = {
	SEO: ['SEO'],
	CRO: ['CRO_exactPath', 'CRO_includePath'],
	ADS: ['ADS_account', 'ADS_campaign', 'ADS_ad', 'ADS_adGroup'],
	SOME: ['SOME_account', 'SOME_campaign', 'SOME_adSet', 'SOME_ad'],
	TASK: [''],
}

export const taskType = [
	'WARNING',
	'ERROR',
	'CRITICAL',
	'GUIDE',
	'INFO',
	'CUSTOM',
]

const blackListedEmails = [
	'0815.ru',
	'0wnd.net',
	'0wnd.org',
	'10minutemail.co.za',
	'10minutemail.com',
	'123-m.com',
	'1fsdfdsfsdf.tk',
	'1pad.de',
	'20minutemail.com',
	'21cn.com',
	'2fdgdfgdfgdf.tk',
	'2prong.com',
	'30minutemail.com',
	'33mail.com',
	'3trtretgfrfe.tk',
	'4gfdsgfdgfd.tk',
	'4warding.com',
	'5ghgfhfghfgh.tk',
	'6hjgjhgkilkj.tk',
	'6paq.com',
	'7tags.com',
	'9ox.net',
	'a-bc.net',
	'agedmail.com',
	'ama-trade.de',
	'amilegit.com',
	'amiri.net',
	'amiriindustries.com',
	'anonmails.de',
	'anonymbox.com',
	'antichef.com',
	'antichef.net',
	'antireg.ru',
	'antispam.de',
	'antispammail.de',
	'armyspy.com',
	'artman-conception.com',
	'azmeil.tk',
	'baxomale.ht.cx',
	'beefmilk.com',
	'bigstring.com',
	'binkmail.com',
	'bio-muesli.net',
	'bobmail.info',
	'bodhi.lawlita.com',
	'bofthew.com',
	'bootybay.de',
	'boun.cr',
	'bouncr.com',
	'breakthru.com',
	'brefmail.com',
	'bsnow.net',
	'bspamfree.org',
	'bugmenot.com',
	'bund.us',
	'burstmail.info',
	'buymoreplays.com',
	'byom.de',
	'c2.hu',
	'card.zp.ua',
	'casualdx.com',
	'cek.pm',
	'centermail.com',
	'centermail.net',
	'chammy.info',
	'childsavetrust.org',
	'chogmail.com',
	'choicemail1.com',
	'clixser.com',
	'cmail.net',
	'cmail.org',
	'coldemail.info',
	'cool.fr.nf',
	'courriel.fr.nf',
	'courrieltemporaire.com',
	'crapmail.org',
	'cust.in',
	'cuvox.de',
	'd3p.dk',
	'dacoolest.com',
	'dandikmail.com',
	'dayrep.com',
	'dcemail.com',
	'deadaddress.com',
	'deadspam.com',
	'delikkt.de',
	'despam.it',
	'despammed.com',
	'devnullmail.com',
	'dfgh.net',
	'digitalsanctuary.com',
	'dingbone.com',
	'disposableaddress.com',
	'disposableemailaddresses.com',
	'disposableinbox.com',
	'dispose.it',
	'dispostable.com',
	'dodgeit.com',
	'dodgit.com',
	'donemail.ru',
	'dontreg.com',
	'dontsendmespam.de',
	'drdrb.net',
	'dump-email.info',
	'dumpandjunk.com',
	'dumpyemail.com',
	'e-mail.com',
	'e-mail.org',
	'e4ward.com',
	'easytrashmail.com',
	'einmalmail.de',
	'einrot.com',
	'eintagsmail.de',
	'emailgo.de',
	'emailias.com',
	'emaillime.com',
	'emailsensei.com',
	'emailtemporanea.com',
	'emailtemporanea.net',
	'emailtemporar.ro',
	'emailtemporario.com.br',
	'emailthe.net',
	'emailtmp.com',
	'emailwarden.com',
	'emailx.at.hm',
	'emailxfer.com',
	'emeil.in',
	'emeil.ir',
	'emz.net',
	'ero-tube.org',
	'evopo.com',
	'explodemail.com',
	'express.net.ua',
	'eyepaste.com',
	'fakeinbox.com',
	'fakeinformation.com',
	'fansworldwide.de',
	'fantasymail.de',
	'fightallspam.com',
	'filzmail.com',
	'fivemail.de',
	'fleckens.hu',
	'frapmail.com',
	'friendlymail.co.uk',
	'fuckingduh.com',
	'fudgerub.com',
	'fyii.de',
	'garliclife.com',
	'gehensiemirnichtaufdensack.de',
	'get2mail.fr',
	'getairmail.com',
	'getmails.eu',
	'getonemail.com',
	'giantmail.de',
	'girlsundertheinfluence.com',
	'gishpuppy.com',
	'gmial.com',
	'goemailgo.com',
	'gotmail.net',
	'gotmail.org',
	'gotti.otherinbox.com',
	'great-host.in',
	'greensloth.com',
	'grr.la',
	'gsrv.co.uk',
	'guerillamail.biz',
	'guerillamail.com',
	'guerrillamail.biz',
	'guerrillamail.com',
	'guerrillamail.de',
	'guerrillamail.info',
	'guerrillamail.net',
	'guerrillamail.org',
	'guerrillamailblock.com',
	'gustr.com',
	'harakirimail.com',
	'hat-geld.de',
	'hatespam.org',
	'herp.in',
	'hidemail.de',
	'hidzz.com',
	'hmamail.com',
	'hopemail.biz',
	'ieh-mail.de',
	'ikbenspamvrij.nl',
	'imails.info',
	'inbax.tk',
	'inbox.si',
	'inboxalias.com',
	'inboxclean.com',
	'inboxclean.org',
	'infocom.zp.ua',
	'instant-mail.de',
	'ip6.li',
	'irish2me.com',
	'iwi.net',
	'jetable.com',
	'jetable.fr.nf',
	'jetable.net',
	'jetable.org',
	'jnxjn.com',
	'jourrapide.com',
	'jsrsolutions.com',
	'kasmail.com',
	'kaspop.com',
	'killmail.com',
	'killmail.net',
	'klassmaster.com',
	'klzlk.com',
	'koszmail.pl',
	'kurzepost.de',
	'lawlita.com',
	'letthemeatspam.com',
	'lhsdv.com',
	'lifebyfood.com',
	'link2mail.net',
	'litedrop.com',
	'lol.ovpn.to',
	'lolfreak.net',
	'lookugly.com',
	'lortemail.dk',
	'lr78.com',
	'lroid.com',
	'lukop.dk',
	'm21.cc',
	'mail-filter.com',
	'mail-temporaire.fr',
	'mail.by',
	'mail.mezimages.net',
	'mail.zp.ua',
	'mail1a.de',
	'mail21.cc',
	'mail2rss.org',
	'mail333.com',
	'mailbidon.com',
	'mailbiz.biz',
	'mailblocks.com',
	'mailbucket.org',
	'mailcat.biz',
	'mailcatch.com',
	'mailde.de',
	'mailde.info',
	'maildrop.cc',
	'maileimer.de',
	'mailexpire.com',
	'mailfa.tk',
	'mailforspam.com',
	'mailfreeonline.com',
	'mailguard.me',
	'mailin8r.com',
	'mailinater.com',
	'mailinator.com',
	'mailinator.net',
	'mailinator.org',
	'mailinator2.com',
	'mailincubator.com',
	'mailismagic.com',
	'mailme.lv',
	'mailme24.com',
	'mailmetrash.com',
	'mailmoat.com',
	'mailms.com',
	'mailnesia.com',
	'mailnull.com',
	'mailorg.org',
	'mailpick.biz',
	'mailrock.biz',
	'mailscrap.com',
	'mailshell.com',
	'mailsiphon.com',
	'mailtemp.info',
	'mailtome.de',
	'mailtothis.com',
	'mailtrash.net',
	'mailtv.net',
	'mailtv.tv',
	'mailzilla.com',
	'makemetheking.com',
	'manybrain.com',
	'mbx.cc',
	'mega.zik.dj',
	'meinspamschutz.de',
	'meltmail.com',
	'messagebeamer.de',
	'mezimages.net',
	'ministry-of-silly-walks.de',
	'mintemail.com',
	'misterpinball.de',
	'moncourrier.fr.nf',
	'monemail.fr.nf',
	'monmail.fr.nf',
	'monumentmail.com',
	'mt2009.com',
	'mt2014.com',
	'mycard.net.ua',
	'mycleaninbox.net',
	'mymail-in.net',
	'mypacks.net',
	'mypartyclip.de',
	'myphantomemail.com',
	'mysamp.de',
	'mytempemail.com',
	'mytempmail.com',
	'mytrashmail.com',
	'nabuma.com',
	'neomailbox.com',
	'nepwk.com',
	'nervmich.net',
	'nervtmich.net',
	'netmails.com',
	'netmails.net',
	'neverbox.com',
	'nice-4u.com',
	'nincsmail.hu',
	'nnh.com',
	'no-spam.ws',
	'noblepioneer.com',
	'nomail.pw',
	'nomail.xl.cx',
	'nomail2me.com',
	'nomorespamemails.com',
	'nospam.ze.tc',
	'nospam4.us',
	'nospamfor.us',
	'nospammail.net',
	'notmailinator.com',
	'nowhere.org',
	'nowmymail.com',
	'nurfuerspam.de',
	'nus.edu.sg',
	'objectmail.com',
	'obobbo.com',
	'odnorazovoe.ru',
	'oneoffemail.com',
	'onewaymail.com',
	'onlatedotcom.info',
	'online.ms',
	'opayq.com',
	'ordinaryamerican.net',
	'otherinbox.com',
	'ovpn.to',
	'owlpic.com',
	'pancakemail.com',
	'pcusers.otherinbox.com',
	'pjjkp.com',
	'plexolan.de',
	'poczta.onet.pl',
	'politikerclub.de',
	'poofy.org',
	'pookmail.com',
	'privacy.net',
	'privatdemail.net',
	'proxymail.eu',
	'prtnx.com',
	'putthisinyourspamdatabase.com',
	'putthisinyourspamdatabase.com',
	'qq.com',
	'quickinbox.com',
	'rcpt.at',
	'reallymymail.com',
	'realtyalerts.ca',
	'recode.me',
	'recursor.net',
	'reliable-mail.com',
	'rhyta.com',
	'rmqkr.net',
	'royal.net',
	'rtrtr.com',
	's0ny.net',
	'safe-mail.net',
	'safersignup.de',
	'safetymail.info',
	'safetypost.de',
	'saynotospams.com',
	'schafmail.de',
	'schrott-email.de',
	'secretemail.de',
	'secure-mail.biz',
	'senseless-entertainment.com',
	'services391.com',
	'sharklasers.com',
	'shieldemail.com',
	'shiftmail.com',
	'shitmail.me',
	'shitware.nl',
	'shmeriously.com',
	'shortmail.net',
	'sibmail.com',
	'sinnlos-mail.de',
	'slapsfromlastnight.com',
	'slaskpost.se',
	'smashmail.de',
	'smellfear.com',
	'snakemail.com',
	'sneakemail.com',
	'sneakmail.de',
	'snkmail.com',
	'sofimail.com',
	'solvemail.info',
	'sogetthis.com',
	'soodonims.com',
	'spam4.me',
	'spamail.de',
	'spamarrest.com',
	'spambob.net',
	'spambog.ru',
	'spambox.us',
	'spamcannon.com',
	'spamcannon.net',
	'spamcon.org',
	'spamcorptastic.com',
	'spamcowboy.com',
	'spamcowboy.net',
	'spamcowboy.org',
	'spamday.com',
	'spamex.com',
	'spamfree.eu',
	'spamfree24.com',
	'spamfree24.de',
	'spamfree24.org',
	'spamgoes.in',
	'spamgourmet.com',
	'spamgourmet.net',
	'spamgourmet.org',
	'spamherelots.com',
	'spamherelots.com',
	'spamhereplease.com',
	'spamhereplease.com',
	'spamhole.com',
	'spamify.com',
	'spaml.de',
	'spammotel.com',
	'spamobox.com',
	'spamslicer.com',
	'spamspot.com',
	'spamthis.co.uk',
	'spamtroll.net',
	'speed.1s.fr',
	'spoofmail.de',
	'stuffmail.de',
	'super-auswahl.de',
	'supergreatmail.com',
	'supermailer.jp',
	'superrito.com',
	'superstachel.de',
	'suremail.info',
	'talkinator.com',
	'teewars.org',
	'teleworm.com',
	'teleworm.us',
	'temp-mail.org',
	'temp-mail.ru',
	'tempe-mail.com',
	'tempemail.co.za',
	'tempemail.com',
	'tempemail.net',
	'tempemail.net',
	'tempinbox.co.uk',
	'tempinbox.com',
	'tempmail.eu',
	'tempmaildemo.com',
	'tempmailer.com',
	'tempmailer.de',
	'tempomail.fr',
	'temporaryemail.net',
	'temporaryforwarding.com',
	'temporaryinbox.com',
	'temporarymailaddress.com',
	'tempthe.net',
	'thankyou2010.com',
	'thc.st',
	'thelimestones.com',
	'thisisnotmyrealemail.com',
	'thismail.net',
	'throwawayemailaddress.com',
	'tilien.com',
	'tittbit.in',
	'tizi.com',
	'tmailinator.com',
	'toomail.biz',
	'topranklist.de',
	'tradermail.info',
	'trash-mail.at',
	'trash-mail.com',
	'trash-mail.de',
	'trash2009.com',
	'trashdevil.com',
	'trashemail.de',
	'trashmail.at',
	'trashmail.com',
	'trashmail.de',
	'trashmail.me',
	'trashmail.net',
	'trashmail.org',
	'trashymail.com',
	'trialmail.de',
	'trillianpro.com',
	'twinmail.de',
	'tyldd.com',
	'uggsrock.com',
	'umail.net',
	'uroid.com',
	'us.af',
	'venompen.com',
	'veryrealemail.com',
	'viditag.com',
	'viralplays.com',
	'vpn.st',
	'vsimcard.com',
	'vubby.com',
	'wasteland.rfc822.org',
	'webemail.me',
	'weg-werf-email.de',
	'wegwerf-emails.de',
	'wegwerfadresse.de',
	'wegwerfemail.com',
	'wegwerfemail.de',
	'wegwerfmail.de',
	'wegwerfmail.info',
	'wegwerfmail.net',
	'wegwerfmail.org',
	'wh4f.org',
	'whyspam.me',
	'willhackforfood.biz',
	'willselfdestruct.com',
	'winemaven.info',
	'wronghead.com',
	'www.e4ward.com',
	'www.mailinator.com',
	'wwwnew.eu',
	'x.ip6.li',
	'xagloo.com',
	'xemaps.com',
	'xents.com',
	'xmaily.com',
	'xoxy.net',
	'yep.it',
	'yogamaven.com',
	'yopmail.com',
	'yopmail.fr',
	'yopmail.net',
	'yourdomain.com',
	'yuurok.com',
	'z1p.biz',
	'za.com',
	'zehnminuten.de',
	'zehnminutenmail.de',
	'zippymail.info',
	'zoemail.net',
	'zomg.info',
	'probdd.com',
	'sunetoa.com',
	'teknowa.com',
	'nubotel.com',
	'turuma.com',
	'xegge.com',
	'rubeshi.com',
]

export const isBlackListedEmail = (email: string): boolean => {
	const emailEnding = email.trim().slice(email.indexOf('@') + 1)
	return blackListedEmails.includes(emailEnding)
}

// Rank predictor options
export const metadataFeatures = [
	'metadata.wordsCount',
	'metadata.totalKeywordCount',
	'metadata.titleKeywordCount',
	'metadata.metadataDescriptionKeywordCount',
	'metadata.imageTitleCount',
	'metadata.imageAltCount',
	'metadata.headingCount',
	'metadata.titleLength',
	'metadata.metaDescriptionLength',
	'metadata.headingKeywordCount',
] as const
export const pagedetailsFeatures = [
	'pageDetails.onpage_score',
	'pageDetails.meta.title',
	'pageDetails.meta.description',
	'pageDetails.meta.meta_keywords',
	'pageDetails.meta.internal_links_count',
	'pageDetails.meta.external_links_count',
	'pageDetails.meta.inbound_links_count',
	'pageDetails.meta.images_count',
	'pageDetails.meta.title_length',
	'pageDetails.meta.description_length',
	'pageDetails.meta.content.plain_text_word_count',
] as const

export const backlinkFeatures = [
	'backlinks.referringDomains',
	'backlinks.backlinks',
	'backlinks.domainsRankTop100',
	'backlinks.domainsRankTop200',
	'backlinks.domainsRankTop300',
	'backlinks.domainsRankTop400',
	'backlinks.domainsRankTop500',
	'backlinks.linksRankTop100',
	'backlinks.linksRankTop200',
	'backlinks.linksRankTop300',
	'backlinks.linksRankTop400',
	'backlinks.linksRankTop500',
	'backlinks.anchorReferringLinks',
	'backlinks.imageReferringLinks',
	'backlinks.linkReferringLinks',
] as const

export enum EAiArticleStatus {
	start = 0,
	subHeadlineDone = 1,
	generatingArticle = 2,
	articleDone = 3,
	generateFail = 4,
}

export enum EAiBulkGenerationType {
	description = 'description',
	category = 'category',
}
export interface IBulkGenerationExcelData {
	productName?: string
	additionalInformation?: string
}
